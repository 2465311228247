import React, { useContext } from "react"
import StateContext from "../StateContext"

import { Link } from "react-router-dom"
import { ReactComponent as BellwayLondonLogo } from "../logos/bellway-london.svg"
import { ReactComponent as BellwayLogo } from "../logos/bellway.svg"

function Header() {
  const appState = useContext(StateContext)

  return (
    <>
      <div
        className={`site-header ${
          appState.brand ? appState.brand : "bellway-london"
        }`}
      >
        <Link to="/">
          {appState.brand === "bellway" ? <BellwayLogo /> : <BellwayLondonLogo />}
        </Link>
      </div>
    </>
  )
}

export default Header
