import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Badge from "react-bootstrap/Badge"
import Loading from "./Loading"
import MapIcon from "./MapIcon"

function MapFilter(props) {
  const { localAreaData, filterMarkers, getDevelopmentCategoryID } = props

  const [activeFilter, setActiveFilter] = useState("all")

  function handleClick(id) {
    filterMarkers(id)
    setActiveFilter(id)
  }

  return (
    <>
      <Button
        key="all"
        className="filter-button mr-1"
        active={activeFilter === "all" ? true : false}
        variant="secondary"
        onClick={() => handleClick("all")}
        size="sm"
      >
        Show All
      </Button>
      {localAreaData ? (
        localAreaData.localAreaCategories.map((item) => {
          //don't show dev in the filter
          if (getDevelopmentCategoryID() !== item.id) {
            return (
              <Button
                key={item.id}
                active={activeFilter === item.id ? true : false}
                className="filter-button mr-1"
                variant="secondary"
                onClick={() => handleClick(item.id)}
                size="sm"
              >
                <Badge
                  pill
                  variant="dark"
                  style={{ backgroundColor: item.color }}
                >
                  <MapIcon name={item.name} />
                </Badge>
                {item.name}{" "}
              </Button>
            )
          }
          return null
        })
      ) : (
        <Loading />
      )}
    </>
  )
}

export default MapFilter
