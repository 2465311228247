import React from "react"
import { priceFormatter } from "./Helpers"

import { ReactComponent as OutdoorSpaceIcon } from "../icons/outdoor-space-icon.svg"
import { ReactComponent as AspectIcon } from "../icons/aspect-icon.svg"
import { ReactComponent as CheckIcon } from "../icons/check-item-icon.svg"
import { ReactComponent as ParkingIcon } from "../icons/parking-icon.svg"

const ItemIcon = (props) => {
  if (props.name === "Outdoor Space") {
    return <OutdoorSpaceIcon />
  } else if (props.name === "Aspect") {
    return <AspectIcon />
  } else if (props.name === "Help to Buy") {
    return <CheckIcon />
  } else if (props.name === "Parking") {
    return <ParkingIcon />
  } else {
    return <CheckIcon />
  }
}

// Filter type corresponding values are:
// Range = 1
// Combobox = 2
// Boolean = 3

// Data Type corresponding values are:
// String = 1
// Date = 2
// Number = 3
// Currency = 4
// Boolean = 5

function CustomFields(props) {
  const { item, view } = props
  if (item.customFields && item.customFields.length >= 1) {
    return item.customFields.map((field) => {
      let val = ""

      //skip if this Building
      if (field.displayName === "Building") {
        return null
      }

      // if currency
      if (field.displaysCurrency && field.dataType === 4) {
        val = priceFormatter(field.fieldValue)

        // if true false
      } else if (field.dataType === 5 && field.fieldValue === "true") {
        val = field.displayName
      } else {
        val = field.fieldValue
      }

      //don't show negative values
      if (
        val !== "None" &&
        val !== "No" &&
        val !== "False" &&
        val !== "false"
      ) {
        if (view && view === "icon-list") {
          return (
            <div className="item-detail" key={field.id}>
              <div className="svg-icon-inline baseline">
                <ItemIcon name={field.displayName} />
              </div>
              {val}
            </div>
          )
        } else {
          //Change the true value to Yes
          if (field.dataType === 5) {
            val = "Yes"
          }
          return (
            <div className="expandable-item" key={field.id}>
              <p className="label">{field.displayName}</p>
              <p>{val}</p>
            </div>
          )
        }
      }
    })
  } else {
    return null
  }
}

export default CustomFields
