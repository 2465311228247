export function priceFormatter(price) {
  let priceFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
  return priceFormatter.format(price).replace(/\D00$/, "")
}

export function dynamicSort(property) {
  var sortOrder = 1
  if (property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

export function stringToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, "") // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  var to = "aaaaeeeeiiiioooouuuunc------"
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes

  return str
}

// get from price from array
export function fromPrice(array) {
  return Math.min.apply(
    Math,
    array
      .filter((i) => {
        if (i > 0) {
          return true
        }
        return false // skip
      })
      .map((i) => {
        return i
      })
  )
}

//get to price from array
export function toPrice(array) {
  return Math.max.apply(
    Math,
    array
      .filter((i) => {
        if (i > 0) {
          return true
        }
        return false // skip
      })
      .map((i) => {
        return i
      })
  )
}

//Status ID
export function getStatusID(array, status) {
  let result = array.filter((el) => {
    if (el.name === status) {
      return el
    }
    return null
  })[0]
  return result.id
}
