import React from "react"

import Routes from "./components/Routes"
import Header from "./components/Header"
import Menu from "./components/Menu"

function App() {
  return (
    <>
      <Header />
      <Menu />
      {/* <BackButton /> */}
      <Routes />
    </>
  )
}

export default App
