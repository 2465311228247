import React from "react"
import { priceFormatter } from "./Helpers"

import { ReactComponent as BedsIcon } from "../icons/beds-icon.svg"
import { ReactComponent as PricesIcon } from "../icons/prices-icon.svg"
import { ReactComponent as AreaIcon } from "../icons/ruler-combined-light.svg"
import { ReactComponent as BuildingIcon } from "../icons/building-icon.svg"
import { ReactComponent as LevelIcon } from "../icons/layer-group-light.svg"

function PlotDetails(props) {
  const { item, status } = props

  let buildingCustomField = null

  if(item.customFields) {
    buildingCustomField = item.customFields.filter((field) => {
      if (field.displayName === "Building") {
        return field
      }
    })[0]
  }
  

  return (
    <>
      {item.plotType.numberOfBeds ? (
        <div className="item-detail beds">
          <div className="svg-icon-inline baseline">
            <BedsIcon />
          </div>
          {item.plotType.numberOfBeds} bedroom home
        </div>
      ) : null}

      {/* {item.floorData && item.floorData.blockName ? (
        <div className="item-detail building">
          <div className="svg-icon-inline baseline">
            <BuildingIcon />
          </div>
          {item.floorData.blockName}
        </div>
      ) : null} */}

      {/* Hack to override the building name with custom field */}
      {buildingCustomField ? (
        <div className="item-detail building">
          <div className="svg-icon-inline baseline">
            <BuildingIcon />
          </div>
          {buildingCustomField.fieldValue}
        </div>
      ) : null}

      {item.floorData && item.floorData.floorName ? (
        <div className="item-detail floor">
          <div className="svg-icon-inline baseline">
            <LevelIcon />
          </div>
          {item.floorData.floorName}
        </div>
      ) : null}

      {item.plotType.imperialArea ? (
        <div className="item-detail area">
          <div className="svg-icon-inline baseline">
            <AreaIcon />
          </div>
          {item.plotType.imperialArea} sq ft
        </div>
      ) : null}
    </>
  )
}

export default PlotDetails
