import React, { useContext } from "react"
import { Link } from "react-router-dom"
import Card from "react-bootstrap/Card"
import Badge from "react-bootstrap/Badge"
import PlotDetails from "./PlotDetails"
import StateContext from "../StateContext"
import FavButton from "../components/FavButton"

import { ReactComponent as Arrow } from "../icons/long-arrow-right-light.svg"

import { priceFormatter } from "./Helpers"

function PlotCard(props) {
  const appState = useContext(StateContext)
  const { item } = props

  //get status
  let status = appState.plotStatuses.filter((el) => {
    if (el.id === item.plotStatusId) {
      return el
    }
    return null
  })[0]

  return (
    // <div className="col-md-4 col-lg-3 plot-card item" key={item.id}>
    <Card className="">
      <Card.Header>
        <div className="card-title">
          <h4 className="plot-name">Plot {item.plotNumber}</h4>
          <h5 className="house-name">{item.name}</h5>
        </div>
        <FavButton plotID={item.id} />{" "}
      </Card.Header>
      <Card.Body>
        <div className="plot-details">
          <PlotDetails item={item} status={status} />
        </div>
      </Card.Body>
      <Card.Footer className="plot-footer">
        {status.name === "Available" ? (
          <span className="price">{priceFormatter(item.price)}</span>
        ) : (
          <span>{status.name}</span>
        )}

        <Link to={`/plots/${item.id}`} className="button-text-link">
          More
          <div className="svg-icon-inline baseline">
            <Arrow />
          </div>
        </Link>
      </Card.Footer>
    </Card>
    // </div>
  )
}

export default PlotCard
