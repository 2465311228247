import React from "react"
import { Link } from "react-router-dom"
import { ReactComponent as Arrow } from "../icons/long-arrow-right-light.svg"

function Menu(props) {
  const { arrowBox, theme, to, onClick } = props

  if (onClick) {
    return (
      <div className={`button-arrow ${arrowBox ? "arrow-box" : ""} ${theme}`}>
        <button onClick={onClick} className="button-text-link uppercase">
          <span className="button-text">{props.children}</span>
          <div className="arrow-wrap">
            <span className="arrow">
              <Arrow />
            </span>
          </div>
        </button>
      </div>
    )
  } else {
    return (
      <div className={`button-arrow ${arrowBox ? "arrow-box" : ""} ${theme}`}>
        <Link to={to} onClick={onClick} className="button-text-link uppercase">
          <span className="button-text">{props.children}</span>
          <div className="arrow-wrap">
            <span className="arrow">
              <Arrow />
            </span>
          </div>
        </Link>
      </div>
    )
  }
}

export default Menu
