import React, { useContext } from "react"
import Page from "../components/Page"
import ZoomContainer from "../components/ZoomContainer"
import StateContext from "../StateContext"
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"
import AreaGuideMenu from "../components/AreaGuideMenu"


function Connections() {
  const appState = useContext(StateContext)

  let connectionsPageContent = appState.devMenuItems.connectionsPage
  let zoomImage = null
  if (connectionsPageContent) {
    zoomImage = connectionsPageContent.find((obj) => {
      return obj.slug === "zoom-image"
    })
  }

  return (
    <Page title="Connections" container={false}>
      
      <BreadcrumbsItem to="/area-guide">Area Guide</BreadcrumbsItem>
      <BreadcrumbsItem to="/connections">Connections</BreadcrumbsItem>
      <ZoomContainer zoomImage={zoomImage} />
      <AreaGuideMenu />
    </Page>
  )
}

export default Connections
