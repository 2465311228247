import React, { useState, useContext } from "react"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"

import Axios from "axios"

import { ReactComponent as Arrow } from "../icons/long-arrow-right-light.svg"

function LoginForm(props) {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  //login
  const [username, setUsername] = useState()

  function handleLogout() {
    appDispatch({ type: "logout" })
    appDispatch({
      type: "updateLocalFavPlots",
      data: [],
    })
  }

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      const response = await Axios.get(`/v2/GetCustomerFavourites/${username}`,
        {
          headers: { "Registration-Key": appState.key }
        }
      )
      // console.log(response.data)
      // we have a user
      appDispatch({ type: "login", data: response.data })

      //add remote plots to local
      appDispatch({
        type: "syncFavPlots",
        data: response.data.customerFavoritePlotIds
      })
    } catch (error) {
      if (error.response.data === "Customer not found.") {
        // console.log("user not found")
        //add new user
        addUser()
      } else {
        // console.log("There was a problem.")
      }
    }
  }

  async function addUser() {
    try {
      const response = await Axios.post("/v2/CreateCustomer/", {
        name: "", //blank do we need?
        surname: "",
        email: username,
      }, {
        headers: { "Registration-Key": appState.key  }
      })
      // console.log(response.data)

      appDispatch({ type: "login", data: response.data })

      // push local favs to remote
      appDispatch({
        type: "updateRemoteFavPlots",
        data: appState.favPlots
      })
    } catch (error) {
      if (error.response.data === "Customer not found.") {
        // console.log("user not found")
      } else {
        // console.log("There was a problem.")
      }
    }
  }

  return (
    <div className="login-form">
      <h2 className="uppercase">Your Account</h2>

      {!appState.loggedIn ? (
        <>
          <p>Please enter your email address as your username to save and keep your favourites within your account.</p>

          <form onSubmit={handleSubmit} className="mb-0 pt-2 pt-md-0">
            {/* <div className="row align-items-center"> */}
            <div>
              <label className="uppercase" htmlFor="email-address">
                Email
              </label>
              <input
                onChange={(e) => setUsername(e.target.value)}
                name="username"
                className="form-control form-control-sm input-dark"
                type="email"
                placeholder=""
                autoComplete="off"
                id="email-address"
              />
            </div>
            <div className="mt-3">
              <button className="button-text-link">
                Sign In{" "}
                <div className="svg-icon-inline baseline">
                  <Arrow />
                </div>
              </button>
            </div>
            <p className="mt-4 small">You are requesting to save your plot detail favourites in a digital format within this application, to do this we only require a valid email address. If you consent a copy of your email address will be kept on record. In the event of any query or complaint in connection with the information we hold about you, please email <a href="mailto:data.protection@bellway.co.uk">data.protection@bellway.co.uk</a> or write to the Data Protection Officer, Bellway Group Office, Woolsington House, Woolsington, Newcastle upon Tyne, Tyne and Wear NE13 8BF</p>
          </form>
        </>
      ) : (
        <div>
          Logged in as: <strong>{appState.user.email} </strong>
          <p>
            <button className="btn btn-secondary mt-2" onClick={handleLogout}>
              Logout
            </button>
          </p>
        </div>
      )}
    </div>
  )
}

export default LoginForm
