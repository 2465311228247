import React, { useContext, useEffect } from "react";
import Iframe from "../components/Iframe";
import StateContext from "../StateContext";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

function Siteplan() {
  const appState = useContext(StateContext);
  
  const siteplan = appState.devMenuItems.siteplan2D
    ? appState.devMenuItems.siteplan2D
    : appState.devMenuItems.siteplan;

  let devName = appState.devName;

  useEffect(() => {
    document.title = `3D Site Plan | ${devName}`;
    window.scrollTo(0, 0);
  }, [devName]);

  return (
    <>
      <Iframe
      src={`${siteplan}?page=sitespin&id=${appState.devID}&key=${appState.key}`}
      ></Iframe>

      {appState.iframeNavigation === "true" ? (
        <>
          <BreadcrumbsItem to="/siteplan">
            {appState.devMenuItems.siteplan2D ? "Site Plan" : "3D Site Plan"}
          </BreadcrumbsItem>
          {appState.zoneClicked !== "" && (
            <BreadcrumbsItem to="/blockplan">
              {appState.zoneClicked}
            </BreadcrumbsItem>
          )}
        </>
      ) : (
        <>
          <BreadcrumbsItem to="/siteplan">
            {appState.developmentType === "Apartments"
              ? "Apartment Finder"
              : "Plot Finder"}
          </BreadcrumbsItem>
          {appState.zoneClicked !== "" && (
            <BreadcrumbsItem to="/blockplan">
              {appState.zoneClicked}
            </BreadcrumbsItem>
          )}
          ;
        </>
      )}
    </>
  );
}

export default Siteplan;

//src={`https://iframespin.bellwaytour.co.uk/?page=sitespin&id=${appState.devID}&key=${appState.key}`}
