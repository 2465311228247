import React, { useContext, useState, useEffect } from "react"
import Page from "../components/Page"
import { Link } from "react-router-dom"
import StateContext from "../StateContext"
import Card from "react-bootstrap/Card"
import Badge from "react-bootstrap/Badge"
import Loading from "../components/Loading"
import {
  priceFormatter,
  dynamicSort,
  stringToSlug,
  getStatusID
} from "../components/Helpers"
import FsLightbox from "fslightbox-react"

import { ReactComponent as BedsIcon } from "../icons/beds-icon.svg"
import { ReactComponent as PricesIcon } from "../icons/prices-icon.svg"

import { ReactComponent as VirtualTourIcon } from "../icons/street-view-light.svg"
import { ReactComponent as ZoomIcon } from "../icons/search-light.svg"

import { ReactComponent as GalleryIcon } from "../icons/gallery-icon.svg"
// import { ReactComponent as FloorplanIcon } from "../icons/floorplans-icon.svg"
import { ReactComponent as ArrowIcon } from "../icons/long-arrow-right-light.svg"

import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"
import { Button } from "react-bootstrap"

import { useMediaQuery } from "react-responsive"
import RefineMenu from "../components/RefineMenu"
import FilterStatusBar from "../components/FilterStatusBar"

function Types() {
  //app state
  const appState = useContext(StateContext)

  const [lightboxController, setLightboxController] = useState({
    toggler: true,
    index: 0,
    openOnMount: false
  })

  const [haveFloorplans, setHaveFloorplans] = useState(false)
  const [gallerySources, setGallerySources] = useState([])
  const [galleryCaptions, setGalleryCaptions] = useState([])

  function updateGallery(index) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      index: index,
      openOnMount: true
    })
  }

  // get the floorplans images
  useEffect(() => {
    const gallerySources = []
    const galleryCaptions = []

    appState.typesData.map((type) => {
      let typeSources = []
      let typeCaptions = []

      //sort images
      let sortedImages = type.assets.slice().sort(dynamicSort("order"))

      sortedImages.map((item) => {
        if (item.name.startsWith("FP")) {
          setHaveFloorplans(true)

          //add image
          typeSources.push(item.fileUrl)

          //add caption

          typeCaptions.push(`${type.name}`)

          // if (item.name.endsWith("0")) {
          //   typeCaptions.push(`${type.name} - Ground Floor`)
          // } else if (item.name.endsWith("1")) {
          //   typeCaptions.push(`${type.name} - First Floor`)
          // } else if (item.name.endsWith("2")) {
          //   typeCaptions.push(`${type.name} - Second Floor`)
          // } else if (item.name.endsWith("3")) {
          //   typeCaptions.push(`${type.name} - Third Floor`)
          // } else if (item.name.endsWith("4")) {
          //   typeCaptions.push(`${type.name} - Fourth Floor`)
          // } else {
          //   typeCaptions.push("")
          // }
        }
        return null
      })

      gallerySources.push(typeSources)
      galleryCaptions.push(typeCaptions)

      return null
    })
    setGallerySources(gallerySources)
    setGalleryCaptions(galleryCaptions)
    // console.log(gallerySources)
  }, [appState.typesData])

  function availableCount(array) {
    var count = 0
    array.map((i) => {
      if (i.plotStatusId === getStatusID(appState.plotStatuses, "Available")) {
        count++
      }
      return null
    })
    return count
  }

  function plotFilteredCheck(plotID) {
    let plotInFiltered = appState.filteredHomes.find((el) => el.id === plotID)
    return plotInFiltered ? true : false
  }

  function filteredCount(array) {
    var count = 0
    array.map((i) => {
      if (plotFilteredCheck(i.id)) {
        count++
      }
      return null
    })
    return count
  }

  const handleMediaQueryChange = (matches) => {
    // switch to the grid view if not desktop
    if (!matches) {
      // setViewState("grid")
    }
  }
  const isDesktopOrLaptop = useMediaQuery(
    { minWidth: 992 },
    undefined,
    handleMediaQueryChange
  )

  return (
    <Page title="Types" fluid={false} container={true} spaceBelow={true}>
      <BreadcrumbsItem to="/types">Types</BreadcrumbsItem>
      <h2 className="page-title">Choose your new home</h2>

      <FilterStatusBar />

      <div className="row">
        {appState.typesData ? (
          appState.typesData.map((item, key) => {
            let sortedImages = item.assets.slice().sort(dynamicSort("order"))
            let img = sortedImages[0]

            let plotStatuses = appState.plotStatuses

            //get avail colour
            let availableStatus = plotStatuses.find(
              (el) => el.name === "Available"
            )

            //get sold colour
            let soldStatus = plotStatuses.find((el) => el.name === "Sold")

            //get current type virtual tour
            const typeTours = appState.devMenuItems
              ? appState.devMenuItems.typeTours
              : ""
            const typeSlug = stringToSlug(item.name)
            // const currentTour = typeTours[typeSlug]
            let currentTour = null
            if (typeTours) {
              currentTour = typeTours.find((el) => el.slug === typeSlug)
            }

            return (
              <>
                {filteredCount(item.plots) ? (
                  <div
                    key={item.id}
                    className="col-md-12 col-xl-4 card-wrap type-card"
                  >
                    <Card className="mb-4 ">
                      <div className="row">
                        <div className="col-md-7 col-xl-12">
                          <div key={img.assetId}>
                            <Card.Img src={img.fileUrl} />
                            <Card.ImgOverlay>
                              <div className="img-overlay-content">
                                {/* {currentTour ? (
                                  <Link
                                    className="overlay-button"
                                    to={`interior-tour/${typeSlug}`}
                                  >
                                    <VirtualTourIcon />
                                  </Link>
                                ) : (
                                  <span className="overlay-button disabled">
                                    <VirtualTourIcon />
                                  </span>
                                )} */}

                                {gallerySources[key] &&
                                gallerySources[key].length > 0 ? (
                                  <button
                                    className="overlay-button"
                                    onClick={() => updateGallery(key)}
                                  >
                                    <ZoomIcon />
                                  </button>
                                ) : null}
                              </div>
                            </Card.ImgOverlay>
                          </div>
                        </div>
                        <div className="col-md-5 col-xl-12">
                          <Card.Header>
                            {item.name}{" "}
                            {filteredCount(item.plots) ===
                            availableCount(item.plots) ? (
                              <span>{filteredCount(item.plots)} Available</span>
                            ) : (
                              <span>
                                {filteredCount(item.plots)}/
                                {availableCount(item.plots)} Filtered
                              </span>
                            )}
                          </Card.Header>
                          <Card.Body>
                            <div className="type-details">
                              <div className="item">
                                <div className="svg-icon-inline baseline">
                                  <BedsIcon />
                                </div>
                                {item.numberOfBeds} bedroom home
                              </div>
                              {filteredCount(item.plots) >= 1 ? (
                                <div className="item">
                                  <div className="svg-icon-inline baseline">
                                    <PricesIcon />
                                  </div>
                                  {availableCount(item.plots)} priced from{" "}
                                  {priceFormatter(item.fromPrice)}
                                </div>
                              ) : null}
                            </div>

                            <div className="plot-list">
                              {item.plots.map((plot) => {
                                if (plotFilteredCheck(plot.id)) {
                                  return (
                                    <div key={plot.id}>
                                      <Link
                                        className="plot-item"
                                        to={`/plots/${plot.id}`}
                                      >
                                        <span>
                                          <strong>
                                            Plot {plot.plotNumber}
                                          </strong>{" "}
                                          {priceFormatter(plot.price)}
                                        </span>
                                        <span className="more-button">
                                          MORE
                                          <div className="svg-icon-inline baseline">
                                            <ArrowIcon />
                                          </div>
                                        </span>
                                      </Link>
                                    </div>
                                  )
                                }
                                return null
                              })}
                            </div>
                          </Card.Body>
                        </div>
                      </div>
                    </Card>
                  </div>
                ) : null}
              </>
            )
          })
        ) : (
          <Loading />
        )}
      </div>
      {haveFloorplans && gallerySources.length > 0 ? (
        <FsLightbox
          toggler={lightboxController.toggler}
          sources={gallerySources[lightboxController.index]}
          captions={galleryCaptions[lightboxController.index]}
          key={lightboxController.index}
          openOnMount={lightboxController.openOnMount}
        />
      ) : null}

      <div className="sub-menu solid-bg centered">
        <div className="container">
          <RefineMenu isDesktopOrLaptop={isDesktopOrLaptop} />
        </div>
      </div>
    </Page>
  )
}

export default Types
