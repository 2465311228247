import React, { useState, useEffect, useCallback, useRef } from "react"

import { Link } from "react-router-dom"

import { ReactComponent as VirtualTourIcon } from "../icons/street-view-light.svg"

function TourMenu(props) {
  const { tours, currentTour } = props

  //tour menu state
  const [isTourMenuOpen, setIsTourMenuOpen] = useState(false)

  const toggleTourMenu = useCallback(() => {
    setIsTourMenuOpen(!isTourMenuOpen)
  }, [isTourMenuOpen])

  const closeTourMenu = useCallback(() => {
    setIsTourMenuOpen(false)
  }, [])

  const openTourMenu = useCallback(() => {
    setIsTourMenuOpen(true)
  }, [])

  /**
   * Hook that alerts clicks outside of the passed ref
   */

  //!event.target.classList.contains("tour-menu-button")

  function useOutsideAlerter(ref) {
    //console.log(ref.current)
    useEffect(() => {
      function handleClickOutside(event) {
        //if not inside the container
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !event.target.classList.contains("tour-menu-button")
        ) {
          //if the menu is open and the click even is not on the button
          if (isTourMenuOpen) {
            closeTourMenu()
          }
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref, isTourMenuOpen])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <>
      {tours && tours.length ? (
        <div className="tour-menu">
          <div
            className={`button round uppercase button-primary box-shadow ${
              isTourMenuOpen ? "active" : ""
            }`}
            onClick={toggleTourMenu}
          >
            <div className="button-text-link">
              <span className="button-text tour-menu-button">
                <div
                  className="svg-icon-inline baseline"
                  style={{ pointerEvents: "none" }}
                >
                  <VirtualTourIcon />
                </div>
                Showhomes
              </span>
            </div>
          </div>

          <div
            className={
              "off-screen-tour-menu " +
              (isTourMenuOpen ? "off-screen-tour-menu--is-visible" : "")
            }
            ref={wrapperRef}
          >
            {tours
              ? tours.map((item, key) => {
                  return (
                    <Link
                      key={key}
                      to={`/interior-tour/${item.slug}`}
                      className={
                        currentTour === item.slug
                          ? "tour-menu-item active"
                          : "tour-menu-item"
                      }
                    >
                      <h5 className="uppercase">{item.title}</h5>
                    </Link>
                  )
                })
              : null}
          </div>
        </div>
      ) : null}{" "}
    </>
  )
}

export default TourMenu
