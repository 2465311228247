import React, { useContext, useRef, useEffect } from 'react';
import StateContext from "../StateContext"
import { ReactComponent as Arrow } from "../icons/long-arrow-left-light.svg"
import { useMediaQuery } from 'react-responsive';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

function HybridPlus() {
	const appState = useContext(StateContext);
	const iFrameRef = useRef(null);
    let devName = appState.devName

	const hybridBrochureURL = appState.devMenuItems.digitalBrochure;

	useEffect(() => {
		document.title = `Digital Brochure | ${devName}`;
		window.scrollTo(0, 0);
	}, [devName]);

	const isMobile = useMediaQuery({
        query: '(max-width: 740px)',
    });

	const prevButton = () => {
		iFrameRef.current.contentWindow.postMessage('show_previous_slide', '*');
	};

	const nextButton = () => {
		iFrameRef.current.contentWindow.postMessage('show_next_slide', '*');
	};

	const pageMenuButton = () => {
		iFrameRef.current.contentWindow.postMessage('show_page_menu', '*');
	};

	return (
		<>
			<iframe
				ref={iFrameRef}
				className='hybrid-plus__container'
				src={hybridBrochureURL}
				width='100%'
				height='100%'
				title='Digital Brochure'
			></iframe>
			<BreadcrumbsItem to="/blockplan">Digital Brochure</BreadcrumbsItem>

			<div className='hybrid-plus__menu'>
				<div className='hybrid-plus__menu__item'>
					<div className='hybrid-plus__prev-button' onClick={prevButton}>
                        <div className="svg-icon-inline baseline"><Arrow /></div>
					</div>
				</div>
				<div className='hybrid-plus__menu__item'>
					<div className='hybrid-plus__page-button' onClick={pageMenuButton}>{isMobile ? (
							<>
								<span className='Menu__Burger__line Menu__Burger__line--1'></span>
								<span className='Menu__Burger__line Menu__Burger__line--2'></span>
								<span className='Menu__Burger__line Menu__Burger__line--3'></span>
							</>
						) : (
							<>Contents</>
						)}
                    </div>
				</div>
				<div className='hybrid-plus__menu__item'>
                    <div className='hybrid-plus__next-button' onClick={nextButton}>
                        <div className="svg-icon-inline baseline"><Arrow /></div>
					</div>
				</div>
			</div>
		</>
	);
}

export default HybridPlus;
