import { useState, useContext, useEffect } from "react";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";
import useWebSocket from "react-use-websocket";
import { useLocation, matchPath } from "react-router-dom";

function LightServer(props) {
  const { lightServerAddress } = props;

  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const { sendMessage } = useWebSocket(lightServerAddress, {
    reconnectAttempts: 604800,
    shouldReconnect: (closeEvent) => true,
  });

  /*
  useEffect(() => {
    console.log("ready state ", readyState);
  }, [readyState]);
*/
  //turn off last light on route change
  const [onPlotPage, setOnPlotPage] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (onPlotPage) {
      //location has changed and we were on a plot page so turn off the current light
      setOnPlotPage(false);
      appDispatch({
        type: "turnOffCurrentLight",
      });
    }
    //check if we are on a plot page
    const match = matchPath(location.pathname, {
      path: "/plots/:id",
      exact: false,
      strict: false,
    });
    if (match) {
      setOnPlotPage(true);
    }
  }, [location]);

  //send message on state change
  useEffect(() => {
    let ID = appState.currentLight.ID;
    let status = appState.currentLight.status;
    if (status === "on" && ID) {
      sendMessage(`{"Command": 7, "LightID": ${ID}}`);
    } else if (status === "off" && ID) {
      sendMessage(`{"Command": 1, "LightID": ${ID}}`);
    }
  }, [appState.currentLight, sendMessage]);

  return null;
}

export default LightServer;
