import React, { useEffect, useContext } from "react"
import StateContext from "../StateContext"
import classnames from "classnames"

function Page(props) {
  const appState = useContext(StateContext)
  let devName = appState.devName

  useEffect(() => {
    document.title = `${props.title} | ${devName}`
    window.scrollTo(0, 0)
  }, [props.title, devName])

  return (
    <div
      className={classnames(
        !props.container ? "page-wrap" : "",
        props.container && props.fluid ? "container-fluid" : "",
        props.container && !props.fluid ? "container" : "",
        props.spaceBelow ? "space-below" : ""
      )}
    >
      {props.children}
    </div>
  )
}

export default Page
