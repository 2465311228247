import React, { useContext } from "react"
import Page from "../components/Page"
import ZoomContainer from "../components/ZoomContainer"
import StateContext from "../StateContext"
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"
import AreaGuideMenu from "../components/AreaGuideMenu"


function MasterplanPage() {
  const appState = useContext(StateContext)

  let MasterplanPageContent = appState.devMenuItems.masterplanPage
  let zoomImage = null
  if (MasterplanPageContent) {
    zoomImage = MasterplanPageContent.find((obj) => {
      return obj.slug === "zoom-image"
    })
  }

  return (
    <Page title="Masterplan" container={false}>
      <BreadcrumbsItem to="/area-guide">Area Guide</BreadcrumbsItem>
      <BreadcrumbsItem to="/masterplan">Masterplan</BreadcrumbsItem>
      <ZoomContainer zoomImage={zoomImage} />
      <AreaGuideMenu />
    </Page>
  )
}

export default MasterplanPage
