import React, { useEffect, useContext } from "react"
import Iframe from "../components/Iframe"
import { useParams } from "react-router-dom"
import StateContext from "../StateContext"
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"
import TourMenu from "../components/TourMenu"

function VirtualTour() {
  const appState = useContext(StateContext)
  const id = useParams().id
  const mainTour =
    appState.devMenuItems && appState.devMenuItems.virtualTour
      ? appState.devMenuItems.virtualTour
      : null
  let devName = appState.devName

  const typeTours =
    appState.devMenuItems && appState.devMenuItems.typeTours
      ? appState.devMenuItems.typeTours
      : null

  useEffect(() => {
    document.title = `Exterior Tour | ${devName}`
    window.scrollTo(0, 0)
  }, [devName])

  return (
    <>
      <BreadcrumbsItem to="/exterior-tour">Exterior Tour</BreadcrumbsItem>
      <Iframe
        src={!id ? mainTour : `${mainTour}?startscene=pano${id}`}
      ></Iframe>

      {typeTours ? (
        <div className="sub-menu centered">
          <div className="container">
            <TourMenu tours={typeTours} currentTour={false} />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default VirtualTour
