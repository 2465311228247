import React, { useContext, useEffect, useCallback, useState } from "react"
import Page from "../components/Page"
import StateContext from "../StateContext"
import { Link } from "react-router-dom"
import { useMediaQuery } from "react-responsive"

import ArrowButton from "../components/ArrowButton"
import ButtonRow from "../components/ButtonRow"

import { ReactComponent as DownArrow } from "../icons/long-arrow-down-light.svg"

import RefineMenu from "../components/RefineMenu"
import HomePagePopover from "../components/HomePagePopover"

function Home(props) {
  const appState = useContext(StateContext)

  let BackgroundImageURL = appState.devStyle.images.backgroundImageUrl

  let backgroundImageStyle = { backgroundImage: `url(${BackgroundImageURL})` }

  let locationImage = null
  let aboutBellwayImage = null

  if (appState.devMenuItems) {
    let homePageContent = appState.devMenuItems.homePage

    if (homePageContent) {
      locationImage = homePageContent.find((obj) => {
        return obj.slug === "location"
      })
      aboutBellwayImage = homePageContent.find((obj) => {
        return obj.slug === "about-bellway"
      })
    }
  }

  //home popover state
  const [isHomePopoverOpen, setIsHomePopoverOpen] = useState(false)

  const toggleHomePopoverOpen = useCallback(() => {
    setIsHomePopoverOpen(!isHomePopoverOpen)
  }, [isHomePopoverOpen])

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 }, undefined)

  return (
    <Page title="Home" container={false}>
      <div className="home-hero" style={backgroundImageStyle}>
        <div className="hero-content fluid-container">
          <div className="row">
            <div className="col-md-5">
              <h1 className="uppercase">Ready for your next move?</h1>
              {appState.devMenuItems && appState.devMenuItems.menuButtons ? (
                <ArrowButton
                  onClick={toggleHomePopoverOpen}
                  theme="button-white"
                  arrowBox
                >
                  FIND YOUR HOME
                </ArrowButton>
              ) : null}
            </div>
          </div>
          <ButtonRow />
        </div>
      </div>

      {isDesktopOrLaptop && appState.devStyle.images.wallIdleVideoUrl ? (
        <video
          className="header-video"
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source
            src={appState.devStyle.images.wallIdleVideoUrl}
            type="video/mp4"
          />
        </video>
      ) : null}

      <HomePagePopover
        isOpen={isHomePopoverOpen}
        setIsOpen={setIsHomePopoverOpen}
      />
    </Page>
  )
}

export default Home
