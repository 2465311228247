import React, { useContext } from "react"
import StateContext from "../StateContext"
import { Link } from "react-router-dom"
import { ReactComponent as Arrow } from "../icons/long-arrow-right-light.svg"
import { ReactComponent as MapIcon } from "../icons/map-location-dot-light.svg"
import { ReactComponent as HomeIcon } from "../icons/house-building-light.svg"

function ButtonRow() {

  const appState = useContext(StateContext)

  return (
    <div className="button-row">
      <Link to="area-guide" className="key-cta key-cta-local-area">
        <div className="icon">
          <MapIcon />
        </div>
        <span className="button-text">Local Area</span>
        <span className="arrow">
          <Arrow />
        </span>
      </Link>
      <Link to="siteplan" className="key-cta cta-homes">
        <div className="icon">
          <HomeIcon />
        </div>
        <span className="button-text"> {appState.developmentType === 'Apartments' ? 'The Apartments' : 'Our Homes'}</span>
        <span className="arrow">
          <Arrow />
        </span>
      </Link>
    </div>
  )
}

export default ButtonRow
