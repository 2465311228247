import React, { useState, useContext, useCallback, useRef } from "react"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"

import Nouislider from "nouislider-react"
import "nouislider/distribute/nouislider.css"
import Form from "react-bootstrap/Form"
import Loading from "../components/Loading"
import { priceFormatter } from "../components/Helpers"
import ArrowButton from "../components/ArrowButton"

import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"

function RefineMenuHome(props) {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  const stateRef = useRef()
  stateRef.current = appState.filters

  // const { filters } = props
  // const { setFilters } = props
  const { isDesktopOrLaptop } = props

  //checkbox

  const handleCheckboxChange = (filterIndex, valueIndex) => (e) => {
    let newFilters = JSON.parse(JSON.stringify(appState.filters))

    newFilters[filterIndex].values[valueIndex].isChecked = !newFilters[
      filterIndex
    ].values[valueIndex].isChecked

    //add active state to filter
    if (newFilters[filterIndex].values.some((e) => e.isChecked)) {
      newFilters[filterIndex].active = true
    } else {
      newFilters[filterIndex].active = false
    }

    // setFilters(newFilters)
    appDispatch({
      type: "setFilters",
      data: newFilters
    })
  }

  //range slider

  //using sep state for the labels
  const [rangeLabels, setRangeLabels] = useState([])

  const rangeOnSlide = (filterID) => (render, handle, value, un, percent) => {
    let newRangeLabels = [...appState.rangeLabels] // copying the old
    newRangeLabels[filterID] = value
    // setRangeLabels(newRangeLabels)
    appDispatch({
      type: "setRangeLabels",
      data: newRangeLabels
    })
  }

  const rangeOnSet = (filterIndex) => (render, handle, value, un, percent) => {
    let newFilters = JSON.parse(JSON.stringify(stateRef.current))

    newFilters[filterIndex].selectedValues = value

    //add active state to filter
    if (
      value[0] !== newFilters[filterIndex].values[0] ||
      value[1] !== newFilters[filterIndex].values[1]
    ) {
      newFilters[filterIndex].active = true
    } else {
      newFilters[filterIndex].active = false
    }

    // setFilters(newFilters)
    appDispatch({
      type: "setFilters",
      data: newFilters
    })
  }

  //update the range labels
  // useEffect(() => {
  //   let newRangeLabels = [...rangeLabels] // copying the old
  //   newRangeLabels[1] = [minPrice, maxPrice]
  //   setRangeLabels(newRangeLabels)
  // }, [filters])

  return (
    <div className="filter-tabs">
      <Tabs defaultActiveKey="1" id="filter-tabs">
        {appState.filters
          ? appState.filters.map((item, filterIndex) => {
              if (item.type === "range") {
                return (
                  <Tab
                    eventKey={item.id}
                    title={item.displayName}
                    key={filterIndex}
                    tabClassName={item.active ? "filtered" : null}
                  >
                    {Number.isInteger(item.values[0]) ? (
                      <>
                        <Nouislider
                          range={{
                            min: item.values[0],
                            max: item.values[1]
                          }}
                          start={item.selectedValues}
                          step={1000}
                          onSet={rangeOnSet(filterIndex)}
                          onSlide={rangeOnSlide(item.id)}
                          connect
                          disabled={false}
                        />
                        <div className="price-range pb-2">
                          {priceFormatter(appState.rangeLabels[item.id][0])}
                          {" - "}
                          {priceFormatter(appState.rangeLabels[item.id][1])}
                        </div>
                      </>
                    ) : (
                      <Loading />
                    )}
                  </Tab>
                )
              } else if (item.type === "checkbox") {
                return (
                  <Tab
                    eventKey={item.id}
                    title={item.displayName}
                    key={filterIndex}
                    tabClassName={item.active ? "filtered" : null}
                  >
                    <Form>
                      {item.values.map((item, valueIndex) => {
                        return (
                          <Form.Check
                            key={valueIndex}
                            label={item.label}
                            checked={item.isChecked}
                            value={item.value}
                            onChange={handleCheckboxChange(
                              filterIndex,
                              valueIndex
                            )}
                            type="checkbox"
                            inline
                            id={`check-${filterIndex}-${valueIndex}`}
                          />
                        )
                      })}
                    </Form>
                  </Tab>
                )
              } else {
                return null
              }
            })
          : null}
      </Tabs>

      {appState.filteredHomes && appState.filteredHomes.length > 0 ? (
        <div className="mt-3">
          <ArrowButton to="/plots" theme="button-secondary">
            View {appState.filteredHomes.length} homes
          </ArrowButton>
        </div>
      ) : (
        <div className="mt-3">No available plots.</div>
      )}
    </div>
  )
}

export default RefineMenuHome
