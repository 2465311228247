import React, { useState, useRef, useContext, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Loading from "./Loading";
import Carousel from "react-bootstrap/Carousel";

import { dynamicSort, getStatusID, priceFormatter } from "./Helpers";

import { ReactComponent as CloseIcon } from "../icons/times-circle-light.svg";
import { ReactComponent as Arrow } from "../icons/long-arrow-right-light.svg";

import PlotDetails from "./PlotDetails";
import CustomFields from "../components/CustomFields";

import PlotCard from "../components/PlotCard";
import Card from "react-bootstrap/Card";
import FavButton from "../components/FavButton";

import Badge from "react-bootstrap/Badge";
function Popover() {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  //check for route change and close popover
  // const location = useLocation()
  // React.useEffect(() => {
  //   appDispatch({ type: "hidePopover" })
  //   console.log("location change")
  // }, [location])

  const [path, setPath] = useState();

  const history = useHistory();
  const location = useLocation();
  //console.log('location.pathname ' + location.pathname);
  const [pathName, setPathName] = useState("");
  const goToRoute = (location) => history.push(location, { from: pathName });

  useEffect(() => {
    if (location.pathname !== undefined) setPathName(location.pathname);
  }, [location.pathname]);

  //location pathname not updating

  //get current location url

  //add body class to lock scroll when popover is open
  React.useEffect(() => {
    if (appState.isPopoverOpen === true) {
      disableBodyScroll(document.querySelector(".off-screen-popover-content"));
      // console.log("true")
    } else {
      enableBodyScroll(document.querySelector(".off-screen-popover-content"));
      // clearAllBodyScrollLocks()
      // console.log("false")
    }
  }, [appState.isPopoverOpen]);

  function closePopover() {
    appDispatch({ type: "hidePopover" });
  }

  function visitLink(location) {
    appDispatch({ type: "hidePopover" });
    enableBodyScroll(document.querySelector(".off-screen-popover-content"));
    goToRoute(location);
  }

  // function openPopover() {
  //   appDispatch({ type: "showPopover" })
  // }

  //plot data
  const currentPlotID = parseInt(appState.popoverContent);
  const [currentPlotData, setCurrentPlotData] = useState();
  const [currentPlotStatus, setCurrentPlotStatus] = useState();
  const [currentTypeData, setCurrentTypeData] = useState();
  const [currentTypePlots, setCurrentTypePlots] = useState();

  useEffect(() => {
    appState.plotsData.filter((plot) => {
      if (plot.id === currentPlotID) {
        //order images
        let sortedImages = plot.plotType.assets
          .slice()
          .sort(dynamicSort("order"));
        plot = { ...plot, sortedImages };

        //order dims
        let sortedDims = plot.plotType.dimensions
          .slice()
          .sort(dynamicSort("orderValue"));
        plot = { ...plot, sortedDims };

        setCurrentPlotData(plot);

        //set current type
        let typePlots = [];
        let currentType = appState.typesData.filter((type) => {
          if (type.id === plot.plotTypeId) {
            //get full plot data for type
            appState.plotsData.filter((plot) => {
              if (
                plot.plotTypeId === type.id &&
                plot.plotStatusId ===
                  getStatusID(appState.plotStatuses, "Available")
              ) {
                typePlots.push(plot);
              }
            });

            return type;
          }
          return null;
        });
        setCurrentTypeData(currentType);
        setCurrentTypePlots(typePlots);

        let status = appState.plotStatuses.filter((status) => {
          if (status.id === plot.plotStatusId) {
            return plot;
          }
          return null;
        })[0];

        setCurrentPlotStatus(status);
      }
      return null;
    });
  }, [appState.plotStatuses, appState.plotsData, currentPlotID]);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (appState.isPopoverOpen) {
            closePopover();
          }
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, appState.isPopoverOpen]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  //Update light server
  React.useEffect(() => {
    if (appState.isPopoverOpen === true) {
      if (currentPlotData) {
        appDispatch({
          type: "setCurrentLight",
          data: { ID: currentPlotData.lightId, status: "on" },
        });
      }
    } else {
      setCurrentPlotData(null);
      appDispatch({
        type: "turnOffCurrentLight",
      });
    }
  }, [appState.isPopoverOpen, currentPlotData]);

  return (
    <div
      className={
        "off-screen-popover " +
        (appState.isPopoverOpen ? "off-screen-popover--is-visible" : "")
      }
    >
      <div className="off-screen-popover-wrap">
        <div className="off-screen-popover-content shadow-sm" ref={wrapperRef}>
          {currentPlotStatus && currentPlotStatus.name !== "Available" ? (
            currentTypePlots && currentTypePlots.length >= 1 ? (
              <div className="">
                <div className="row">
                  <div className="col-md-12">
                    {currentPlotStatus.name ? (
                      <p>
                        This plot is {currentPlotStatus.name.toLowerCase()}{" "}
                        below are some available plots of same type
                      </p>
                    ) : (
                      <p>
                        This plot is not available below are some available
                        plots of same type
                      </p>
                    )}
                  </div>
                </div>
                <div className="plots-grid row">
                  {currentTypePlots.map((plot) => {
                    return (
                      <div
                        className="col-md-6 col-lg-6 plot-card item"
                        key={plot.id}
                      >
                        <PlotCard item={plot} key={plot.id} />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : currentPlotStatus.name ? (
              <div className="mt-3">
                <p>This plot is {currentPlotStatus.name.toLowerCase()}.</p>
              </div>
            ) : (
              <div className="mt-3">
                <p>This plot is not available.</p>
              </div>
            )
          ) : null}

          {currentPlotData && currentPlotStatus.name === "Available" ? (
            <div className="row">
              <div className="col-md-6">
                <Card className="mb-4 plot-card single">
                  <Card.Header>
                    <div className="card-title">
                      <h4 className="plot-name">
                        Plot {currentPlotData.plotNumber}
                      </h4>
                      <h5 className="house-name">{currentPlotData.name}</h5>
                    </div>
                    <FavButton plotID={currentPlotData.id} />{" "}
                  </Card.Header>
                  <Card.Body>
                    <div className="plot-details">
                      <PlotDetails
                        item={currentPlotData}
                        status={currentPlotStatus}
                      />
                      <CustomFields item={currentPlotData} view="icon-list" />
                    </div>
                  </Card.Body>
                  <Card.Footer className="plot-footer">
                    {currentPlotStatus.name === "Available" ? (
                      <>
                        <span className="price">
                          {priceFormatter(currentPlotData.price)}
                        </span>
                        <button
                          className="button-text-link white"
                          onClick={() =>
                            visitLink(`/plots/${currentPlotData.id}`)
                          }
                        >
                          More
                          <div className="svg-icon-inline baseline">
                            <Arrow />
                          </div>
                        </button>
                      </>
                    ) : (
                      <span className="status">{currentPlotStatus.name}</span>
                    )}
                  </Card.Footer>
                </Card>
              </div>
              <div className="col-md-6">
                <Carousel interval={null} controls={false}>
                  {currentPlotData.sortedImages.map((img) => {
                    return (
                      <Carousel.Item key={img.assetId}>
                        <img
                          className="d-block w-100"
                          src={img.fileUrl}
                          alt=""
                        />
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          ) : null}
        </div>

        <div className="close-button-row">
          <div className="close-button" onClick={closePopover}>
            <CloseIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popover;
