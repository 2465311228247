import React from "react"

import { ReactComponent as BarsIcon } from "../icons/bars-icon.svg"
import { ReactComponent as HealthIcon } from "../icons/health-icon.svg"
import { ReactComponent as ShoppingIcon } from "../icons/shopping-icon.svg"
import { ReactComponent as TravelIcon } from "../icons/travel-icon.svg"
import { ReactComponent as EducationIcon } from "../icons/education-icon.svg"
import { ReactComponent as ParksIcon } from "../icons/parks-icon.svg"

function MapIcon(props) {

  const name = props.name.toLowerCase()
  let icon = ''

  switch(name) {
    case "bar":
    case "restaurant":
    case "bars & restaurant":
      icon = <BarsIcon />
      break

    case "parks":
    case "culture":
      icon = <ParksIcon />
      break

    case "education":
      icon = <EducationIcon />
      break

    case "health":
    case "fitness":
      icon = <HealthIcon />
      break

    case "shopping":
    case "supermarket":
      icon = <ShoppingIcon />
      break

    case "travel":
    case "bank":
      icon = <TravelIcon />
      break

    default:
      icon = <ParksIcon />
      break
  }

  return icon

}

export default MapIcon
