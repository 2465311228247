import React, { useContext } from "react"
import Page from "../components/Page"
import ZoomContainer from "../components/ZoomContainer"
import StateContext from "../StateContext"
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"

function Facilities() {
  const appState = useContext(StateContext)

  let facilitiesPageContent = appState.devMenuItems.facilitiesPage
  let zoomImage = null
  if (facilitiesPageContent) {
    zoomImage = facilitiesPageContent.find((obj) => {
      return obj.slug === "zoom-image"
    })
  }

  return (
    <Page title="Facilities" container={false}>
      <BreadcrumbsItem to="/facilities">Facilities</BreadcrumbsItem>
      <ZoomContainer zoomImage={zoomImage} />
    </Page>
  )
}

export default Facilities
