import { React, useState, useContext, useEffect, Suspense } from "react";
import StateContext from "../StateContext";
import Axios from "axios";
import Fancybox from "../components/Fancybox";
import { SuspenseImg } from "../components/SuspenseImage";
import Page from "../components/Page";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { ReactComponent as PlayIcon } from "../icons/play-circle-duotone.svg";
import { useMediaQuery } from "react-responsive";

function Gallery() {
  const appState = useContext(StateContext);
  const [galleryData, setGalleryData] = useState();
  const [galleryKey, setGalleryKey] = useState(0);
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 });

  //get the gallery data from api
  useEffect(() => {
    if (!localStorage.getItem("galleryData")) {
      const ourRequest = Axios.CancelToken.source();
      async function fetchResults() {
        try {
          const response = await Axios.get(
            `/v1/GetGalleries/${appState.devID}/true`,
            {
              cancelToken: ourRequest.token,
              headers: { "Registration-Key": appState.key },
            }
          );
          if (response.data) {
            //set state
            setGalleryData(response.data);

            //set localStorage
            let json = JSON.stringify(response.data);
            localStorage.setItem("galleryData", json);
          }
        } catch (e) {
          console.log("There was a problem or the request was cancelled.");
        }
      }
      fetchResults();
      return () => ourRequest.cancel();
    } else {
      //we have local data
      //set state
      const data = JSON.parse(localStorage.getItem("galleryData"));
      setGalleryData(data);
    }
  }, [appState.devID, appState.key]);

  function changeGallery(key) {
    setGalleryKey(key);
  }

  function LoadingImages() {
    return (
      <div className="Gallery__Grid">
        {galleryData && galleryData[galleryKey].assets
          ? galleryData[galleryKey].assets.map((asset, assetsIndex) => {
              return (
                <div
                  className="Gallery__Item Gallery__Item--skeleton"
                  key={`${galleryKey}_${assetsIndex}`}
                ></div>
              );
            })
          : null}
      </div>
    );
  }

  function ShowImages() {
    return (
      <Page title="Gallery" container={true} fluid={true} spaceBelow={true}>
        <BreadcrumbsItem to="/gallery">Gallery</BreadcrumbsItem>
        <h2 className="page-title">Gallery</h2>

        <div className="gallery-menu">
          {galleryData
            ? galleryData.map((gallery, index) => {
                return (
                  <button
                    key={index}
                    className={`button round button-outline ${
                      index === galleryKey ? "active" : ""
                    }`}
                    onClick={() => changeGallery(index)}
                    active={index === galleryKey ? "true" : "false"}
                  >
                    <span className="button-text">{gallery.name}</span>
                  </button>
                );
              })
            : null}
        </div>

        <Fancybox
          options={{
            Toolbar: false,
            closeButton: "outside",
            Image: {
              zoom: false,
            },
          }}
        >
          <div className="Gallery__Grid">
            {galleryData &&
            galleryData[galleryKey] &&
            galleryData[galleryKey].assets
              ? galleryData[galleryKey].assets.map((asset, assetsIndex) => {
                  if (
                    asset.fileUrl.endsWith("jpg") ||
                    asset.fileUrl.endsWith("jpeg")
                  ) {
                    return (
                      <a
                        className="Gallery__Item"
                        key={`${galleryKey}_${assetsIndex}`}
                        data-fancybox={`gallery_${galleryKey}`}
                        href={asset.fileUrl}
                        data-caption={asset.name}
                      >
                        <div className="Gallery__Item__Caption">
                          {asset.name}
                        </div>
                        <SuspenseImg alt={asset.name} src={asset.fileUrl} />
                      </a>
                    );
                  } else if (asset.fileUrl.endsWith("mp4")) {
                    return (
                      <a
                        className="Gallery__Item Gallery__Item--video"
                        key={`${galleryKey}_${assetsIndex}`}
                        data-fancybox={`gallery_${galleryKey}`}
                        href={asset.fileUrl}
                      >
                        <div className="Gallery__Item__PlayIcon">
                          <PlayIcon />
                        </div>
                        <video width="400" preload="auto" muted>
                          <source
                            src={
                              isDesktopOrLaptop
                                ? `${asset.fileUrl}#t=1.0`
                                : asset.fileUrl
                            }
                            type="video/mp4"
                          />
                        </video>
                      </a>
                    );
                  } else {
                    return null;
                  }
                })
              : null}
          </div>
        </Fancybox>
      </Page>
    );
  }

  return (
    <div className="Gallery">
      <Suspense fallback={<LoadingImages />}>
        {galleryData ? <ShowImages /> : null}
      </Suspense>
    </div>
  );
}

export default Gallery;
