import React, { useContext, useEffect } from "react";
import Iframe from "../components/Iframe";
import StateContext from "../StateContext";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

function Blockplan() {
  const appState = useContext(StateContext);
  const siteplan = appState.devMenuItems.siteplan;
  let devName = appState.devName;

  useEffect(() => {
    document.title = `Apartment Finder | ${devName}`;
    window.scrollTo(0, 0);
  }, [devName]);

  return (
    <>
      <BreadcrumbsItem to="/blockplan">Apartment Finder</BreadcrumbsItem>
      <Iframe
      src={`${siteplan}?page=blockplan&id=${appState.devID}&key=${appState.key}`}
      ></Iframe>
    </>
  );
}

export default Blockplan;

//src={`https://www.assets.think-online.co.uk/dev/spins/index.html?page=blockplan&type=${appState.developmentType}`}