import React, { useContext, useEffect, useState } from "react"
import Page from "../components/Page"

import StateContext from "../StateContext"

import { ReactComponent as ZoomInIcon } from "../icons/search-plus-light.svg"
import { ReactComponent as ZoomOutIcon } from "../icons/search-minus-light.svg"

import { ReactComponent as MapIcon } from "../icons/map-marker-check-light.svg"

import ArrowButton from "../components/ArrowButton"
import AreaGuideMenu from "../components/AreaGuideMenu"

import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

import ReactHtmlParser from "react-html-parser"
import Axios from "axios"

function Home() {
  const appState = useContext(StateContext)

  let locationPageContent = appState.devMenuItems.locationPage
  let heroImage = null
  let subImage = null
  let zoomImage = null
  let zoomImageTwo = null
  let text1 = null
  if (locationPageContent) {
    heroImage = locationPageContent.find((obj) => {
      return obj.slug === "hero-image"
    })
    subImage = locationPageContent.find((obj) => {
      return obj.slug === "sub-image"
    })
    zoomImage = locationPageContent.find((obj) => {
      return obj.slug === "zoom-image"
    })
    zoomImageTwo = locationPageContent.find((obj) => {
      return obj.slug === "zoom-image-2"
    })
    text1 = locationPageContent.find((obj) => {
      return obj.slug === "text-section-1"
    })
  }

  const [locationText, setLocationText] = useState()

  //get the location text from url
  useEffect(() => {
    if (!localStorage.getItem("locationText")) {
      const ourRequest = Axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await Axios.get(`${text1.url}`, {
            cancelToken: ourRequest.token
          })
          if (response.data) {
            setLocationText(response.data)
            localStorage.setItem("locationText", response.data)
          }
        } catch (e) {
          console.log("There was a problem or the request was cancelled.")
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    } else {
      //we have local data
      //set state
      setLocationText(localStorage.getItem("locationText"))
    }
  }, [])

  return (
    <Page title="Local Area" container={false} spaceBelow={true}>

      <AreaGuideMenu />

      <BreadcrumbsItem to="/area-guide">Area Guide</BreadcrumbsItem>

      <div
        className="local-area-hero"
        style={{
          backgroundImage: `url(${
            heroImage && heroImage.url ? heroImage.url : ""
          })`
        }}
      >
        <div className="hero-content">
          <h1 className="uppercase">AN UNRIVALLED LOCATION</h1>
        </div>

        {/* <div className="image-caption">
          Aerial photograph of Lexington Gardens, Nine Elms
        </div> */}
      </div>

      <div className="about-text container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-6 mb-2">
            {locationText ? ReactHtmlParser(locationText) : null}
          </div>
          {subImage ? (
            <div className="col-lg-6">
              <div className="wrap">
                <img
                  src={subImage.url}
                  alt={subImage.title}
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {zoomImage ? (
        <div className="zoom-image-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <TransformWrapper wheel={{ wheelEnabled: false }}>
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                      <div className="transform-container">
                        <TransformComponent>
                          <div>
                            <img src={zoomImage.url} alt={zoomImage.title} />
                          </div>
                        </TransformComponent>
                      </div>

                      <div className="tools">
                        <button onClick={zoomOut}>
                          <div className="svg-icon-inline baseline">
                            <ZoomOutIcon />
                          </div>
                        </button>
                        <button onClick={zoomIn}>
                          <div className="svg-icon-inline baseline">
                            <ZoomInIcon />
                          </div>
                        </button>
                        {/* <button onClick={resetTransform}>x</button> */}
                      </div>
                    </React.Fragment>
                  )}
                </TransformWrapper>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className=" container mt-4">
        <div className="cta-section container">
          <div className="row">
            <div className="col-lg-6 cta-col">
              <h2 className="uppercase">
                <div className="svg-icon-inline baseline">
                  <MapIcon />
                </div>
                Local Area Map
              </h2>
            </div>
            <div className="col-lg-6 cta-col">
              <ArrowButton to="/map" theme="button-primary">
                explore here
              </ArrowButton>
            </div>
          </div>
        </div>
      </div>

      {zoomImageTwo ? (
        <div className="zoom-image-section mt-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <TransformWrapper wheel={{ wheelEnabled: false }}>
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                      <div className="transform-container">
                        <TransformComponent>
                          <div>
                            <img
                              src={zoomImageTwo.url}
                              alt={zoomImageTwo.title}
                            />
                          </div>
                        </TransformComponent>
                      </div>

                      <div className="tools">
                        <button onClick={zoomOut}>
                          <div className="svg-icon-inline baseline">
                            <ZoomOutIcon />
                          </div>
                        </button>
                        <button onClick={zoomIn}>
                          <div className="svg-icon-inline baseline">
                            <ZoomInIcon />
                          </div>
                        </button>

                        {/* <button onClick={resetTransform}>x</button> */}
                      </div>
                    </React.Fragment>
                  )}
                </TransformWrapper>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      
    </Page>
  )
}

export default Home
