import React, { useEffect, useState, useContext, useRef } from "react"

import { Link } from "react-router-dom"
import Card from "react-bootstrap/Card"
import Badge from "react-bootstrap/Badge"
import PlotDetails from "./PlotDetails"
import StateContext from "../StateContext"
import FavButton from "../components/FavButton"
import CustomFields from "../components/CustomFields"

import { ReactComponent as ArrowIcon } from "../icons/long-arrow-right-light.svg"
import { ReactComponent as ArrowCircleUpIcon } from "../icons/arrow-circle-up-light.svg"
import { ReactComponent as ArrowCircleDownIcon } from "../icons/arrow-circle-down-light.svg"

import { priceFormatter } from "./Helpers"

function PlotCardList(props) {
  const appState = useContext(StateContext)
  const { item, data } = props

  const [isExpanded, setIsExpanded] = useState(false)
  const rowDetailsRef = useRef(null)

  // get status
  let status = appState.plotStatuses.filter((el) => {
    if (el.id === item.plotStatusId) {
      return el
    }
    return null
  })[0]

  const onTransition = () => {
    if (rowDetailsRef.current && !rowDetailsRef.current.clientHeight) {
      setIsExpanded(!isExpanded)
    }
  }

  useEffect(() => {
    window.addEventListener("transitionend", onTransition)
    return () => {
      window.removeEventListener("transitionend", onTransition)
    }
  })

  const expand = () => {
    if (!rowDetailsRef.current) {
      return
    }

    const height = rowDetailsRef.current.scrollHeight
    rowDetailsRef.current.style.height = `${height}px`
    rowDetailsRef.current.style.opacity = "1"
    rowDetailsRef.current.style.transition =
      "height 0.25s linear, opacity 0.25s ease-in"
  }

  const collapse = () => {
    if (!rowDetailsRef.current) {
      return
    }

    rowDetailsRef.current.style.height = "0"
    rowDetailsRef.current.style.opacity = "0"
    rowDetailsRef.current.style.transition =
      "height 0.25s linear, opacity 0.25s ease-in"
  }

  const toggleRow = async () => {
    if (!isExpanded) {
      await setIsExpanded(!isExpanded)
      expand()
    } else {
      collapse()
    }
  }

  const dataRowClasses = `table-row ${isExpanded ? "selected" : ""}`
  const dataRow = (
    <tr className={dataRowClasses} key={`row-${item.id}`} onClick={toggleRow}>
      <td className="expand-button">
        <span className="expand-arrow">
          {isExpanded ? <ArrowCircleUpIcon /> : <ArrowCircleDownIcon />}
        </span>
      </td>
      <td>
        <FavButton plotID={item.id} />
      </td>
      <td>{item.plotNumber}</td>

      {item.floorData ? <td>{item.floorData.floorName}</td> : <td>-</td>}

      <td>{item.name}</td>
      <td>{item.plotType.numberOfBeds} bed</td>
      <td>
        {status.name === "Available" ? (
          <span className="price">{priceFormatter(item.price)}</span>
        ) : (
          <span>{status.name}</span>
        )}
      </td>
      <td>
        <Link to={`/plots/${item.id}`} className="button-text-link">
          More
          <div className="svg-icon-inline baseline">
            <ArrowIcon />
          </div>
        </Link>
      </td>
    </tr>
  )

  const detailsRow = isExpanded ? (
    <tr className="row-details" key={`row-details-${item.id}`}>
      <td colSpan="7">
        <div className="expandable-wrapper" ref={rowDetailsRef}>
          <div className="expandable-content">
            {item.plotType.imperialArea ? (
              <div className="expandable-item">
                <p className="label">Area</p>
                <p>{item.plotType.imperialArea} sq ft</p>
              </div>
            ) : null}

            {item.floorData.blockName ? (
              <div className="expandable-item">
                <p className="label">Building</p>
                <p>{item.floorData.blockName}</p>
              </div>
            ) : null}

            <CustomFields item={item} view="label-list" />
          </div>
        </div>
      </td>
    </tr>
  ) : null

  return [dataRow, detailsRow]
}

export default PlotCardList
