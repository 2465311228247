import React, { useEffect, useState, useContext } from "react"

import { useParams, useSearchParams } from "react-router-dom"

import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"

import { priceFormatter, dynamicSort } from "../components/Helpers"

import { Link } from "react-router-dom"
import { useCookies } from "react-cookie"
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"
import Card from "react-bootstrap/Card"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import Button from "react-bootstrap/Button"

import Page from "../components/Page"
import LoginForm from "../components/LoginForm"
import PlotCard from "../components/PlotCard"
import FavButton from "../components/FavButton"
import FavouritesQRCode from "../components/FavouritesQRCode"
import FsLightbox from "fslightbox-react"

import { ReactComponent as GridIcon } from "../icons/grid-icon.svg"
import { ReactComponent as CompareIcon } from "../icons/compare-icon.svg"
import { ReactComponent as ZoomIcon } from "../icons/search-light.svg"

function Favourite() {
  //app state
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  let { view } = useParams()
  let defaultView = view ? view : "grid"

  const [favHomes, setFavHomes] = useState([])

  const [favHomesCustomFields, setSavHomesCustomField] = useState([])
  const [faveHomeFloorData, setFaveHomeFloorData] = useState(false)
  const [faveHomeBuildingCustomField, setFaveHomeBuildingCustomField] =
    useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(["inMarketingSuite"])

  //view state
  const [viewState, setViewState] = useState(defaultView)
  const [inMarketingSuite, setInMarketingSuite] = useState(
    cookies.inMarketingSuite === "true" ? true : false
  )

  useEffect(() => {
    //filter out only show fav homes.
    if (appState.plotsData && appState.favPlots) {
      let favHomes = appState.plotsData.filter((el) => {
        if (appState.favPlots.includes(el.id)) {
          return el
        }
        return null
      })
      setFavHomes(favHomes)
    }
  }, [appState.favPlots, appState.plotsData])

  //get full list of custom fields
  useEffect(() => {
    let usedCustomFields = []

    favHomes.filter((el) => {
      if (el.customFields) {
        el.customFields.filter((cf) => {
          if (cf && cf.dataFilterType !== null) {
            usedCustomFields.push(cf)
            return cf
          } else {
            return null
          }
        })
      }
      return null
    })

    let customFiltersIDs = [
      ...new Set(usedCustomFields.flatMap(({ id }) => id))
    ].sort()

    let favCustomField = []

    customFiltersIDs.forEach(function (id) {
      //get this field
      let field = usedCustomFields.filter((cf) => {
        if (cf.id === id) {
          return cf
        }
      })
      favCustomField.push({
        id: field[0].id,
        displayName: field[0].displayName,
        dataFilterType: field[0].dataFilterType
      })
    })
    setSavHomesCustomField(favCustomField)
  }, [favHomes])

  //Check for floor data on one plot
  useEffect(() => {
    let faveHomeFloorData = favHomes.filter((el) => {
      if (el.floorData) {
        return el
      } else {
        return null
      }
    })

    if (faveHomeFloorData && faveHomeFloorData.length >= 1) {
      setFaveHomeFloorData(true)
    }
  }, [favHomes])

  //Check for custom field building name
  useEffect(() => {
    let haveCustomBuilding = false

    favHomes.filter((el) => {
      if (el.customFields) {
        el.customFields.filter((field) => {
          if (field.displayName === "Building") {
            haveCustomBuilding = true
            return field
          } else {
            return null
          }
        })
      } else {
        return null
      }
    })

    if (haveCustomBuilding) {
      setFaveHomeBuildingCustomField(true)
    }
  }, [favHomes])

  //set up gallery
  const [lightboxController, setLightboxController] = useState({
    toggler: true,
    index: 0,
    openOnMount: false
  })

  const [haveFloorplans, setHaveFloorplans] = useState(false)
  const [gallerySources, setGallerySources] = useState([])
  const [galleryCaptions, setGalleryCaptions] = useState([])

  function getTypeKey(val) {
    for (var i = 0; i < appState.typesData.length; i++)
      if (appState.typesData[i].id === val) return i
    return false
  }

  function getFloorName(name) {
    if (name.endsWith("0")) {
      return "Ground Floor"
    } else if (name.endsWith("1")) {
      return "First Floor"
    } else if (name.endsWith("2")) {
      return "Second Floor"
    } else if (name.endsWith("3")) {
      return "Third Floor"
    } else if (name.endsWith("4")) {
      return "Fourth Floor"
    } else {
      return ""
    }
  }

  // Update the gallery to show using the type ID
  function updateGallery(typeId) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      index: getTypeKey(typeId),
      openOnMount: true
    })
  }

  // get the floorplans images
  useEffect(() => {
    const gallerySources = []
    const galleryCaptions = []

    appState.typesData.map((type) => {
      let typeSources = []
      let typeCaptions = []

      //sort images
      let sortedImages = type.assets.slice().sort(dynamicSort("order"))

      sortedImages.map((item) => {
        if (item.name.startsWith("FP")) {
          setHaveFloorplans(true)

          //add image
          typeSources.push(item.fileUrl)

          //add caption
          typeCaptions.push(getFloorName(item.name))
        }
        return null
      })

      gallerySources.push(typeSources)
      galleryCaptions.push(typeCaptions)

      return null
    })
    setGallerySources(gallerySources)
    setGalleryCaptions(galleryCaptions)
  }, [appState.typesData])

  //clearFaves
  function clearFavPlots() {
    appDispatch({ type: "clearFavPlots" })
  }

  let numberFavClass =
    favHomes.length === 1 || favHomes.length === 2 ? "two-up" : ""

  return (
    <Page
      title="Your Favourites"
      container={false}
      fluid={false}
      spaceBelow={true}
    >
      <BreadcrumbsItem to="/favourites">Your Favourites</BreadcrumbsItem>
      <h2 className="page-title">Your Favourites</h2>

      {viewState === "grid" ? (
        <div className="container">
          <div className="plots-grid" key="plots-grid">
            <div className="plots-list row">
              {favHomes.length >= 1 ? (
                favHomes.map((item) => {
                  return (
                    <div
                      className="col-md-4 col-lg-3 plot-card item mb-4"
                      key={item.id}
                    >
                      <PlotCard item={item} key={item.id} />
                    </div>
                  )
                })
              ) : (
                <div className="col-md-12 plot-card item" key={0}>
                  <Card className="mb-4 shadow-sm">
                    <Card.Body>
                      Looks like you don't have any favourites.
                    </Card.Body>
                  </Card>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={`plots-compare ${numberFavClass}`} key="plots-compare">
          <div className="compare-table-wrap">
            <div className="compare-table">
              <table>
                <tbody>
                  <tr>
                    <td></td>

                    {favHomes.length >= 1 ? (
                      favHomes.map((item, index) => {
                        return (
                          <td key={`fav-card-${item.id}-${index}`}>
                            <div className="card-wrap">
                              <div className="card-title">
                                <h4 className="plot-name">
                                  <Link to={`/plots/${item.id}`}>
                                    Plot {item.plotNumber}
                                  </Link>
                                </h4>
                                <h5 className="house-name">{item.name}</h5>
                              </div>
                              <FavButton plotID={item.id} />{" "}
                            </div>
                          </td>
                        )
                      })
                    ) : (
                      <td>Looks like you don't have any favourites.</td>
                    )}
                  </tr>

                  {favHomes.length >= 1 ? (
                    <tr>
                      <th>Price</th>
                      {favHomes.length >= 1
                        ? favHomes.map((item, index) => {
                            return (
                              <td key={`fav-price-${item.id}-${index}`}>
                                <div className="item-wrap price">
                                  {priceFormatter(item.price)}
                                </div>
                              </td>
                            )
                          })
                        : null}
                    </tr>
                  ) : null}

                  {favHomes.length >= 1 ? (
                    <tr>
                      <th>Bedrooms</th>
                      {favHomes.length >= 1
                        ? favHomes.map((item, index) => {
                            return (
                              <td key={`fav-beds-${item.id}-${index}`}>
                                <div className="item-wrap">
                                  {item.plotType.numberOfBeds} bed
                                </div>
                              </td>
                            )
                          })
                        : null}
                    </tr>
                  ) : null}

                  {favHomes.length >= 1 ? (
                    faveHomeFloorData ? (
                      <tr>
                        <th>Floor</th>
                        {favHomes.length >= 1
                          ? favHomes.map((item, index) => {
                              return (
                                <td key={`fav-floor-${item.id}-${index}`}>
                                  <div className="item-wrap">
                                    {item.floorData
                                      ? item.floorData.floorName
                                      : "-"}
                                  </div>
                                </td>
                              )
                            })
                          : null}
                      </tr>
                    ) : null
                  ) : null}

                  {favHomes.length >= 1 ? (
                    <tr>
                      <th>Area</th>
                      {favHomes.length >= 1
                        ? favHomes.map((item, index) => {
                            return (
                              <td key={`fav-area-${item.id}-${index}`}>
                                <div className="item-wrap">
                                  {item.plotType.imperialArea} sq ft
                                </div>
                              </td>
                            )
                          })
                        : null}
                    </tr>
                  ) : null}

                  {favHomes.length >= 1 && haveFloorplans ? (
                    <tr>
                      <th>Floorplans</th>
                      {favHomes.length >= 1
                        ? favHomes.map((item, index) => {
                            //filter and sort images
                            let sortedImages = item.plotType.assets
                              .filter((image) => {
                                if (image.name.startsWith("FP")) {
                                  return image
                                }
                                return null
                              })
                              .slice()
                              .sort(dynamicSort("order"))

                            return (
                              <td key={`fav-floorplan-${item.id}-${index}`}>
                                <div className="item-wrap">
                                  {sortedImages.map((image) => {
                                    if (image.name.startsWith("FP")) {
                                      return (
                                        <div className="floor">
                                          {sortedImages.length > 1 ? (
                                            <p className="floor-title">
                                              {getFloorName(image.name)}
                                            </p>
                                          ) : null}

                                          <img
                                            src={image.fileUrl}
                                            alt={getFloorName(image.name)}
                                            className="floor-image"
                                          />
                                        </div>
                                      )
                                    }
                                    return null
                                  })}

                                  {gallerySources[index] &&
                                  gallerySources[index].length > 0 ? (
                                    <button
                                      className="button button-secondary plans-gallery-button"
                                      onClick={() =>
                                        updateGallery(item.plotType.id)
                                      }
                                    >
                                      <div className="svg-icon-inline baseline">
                                        <ZoomIcon />
                                      </div>
                                      {sortedImages.length > 1
                                        ? "View Plans"
                                        : "View Plan"}
                                    </button>
                                  ) : null}
                                </div>
                              </td>
                            )
                          })
                        : null}
                    </tr>
                  ) : null}

                  {favHomes.length >= 1 ? (
                    <tr>
                      <th>Dimensions</th>
                      {favHomes.length >= 1
                        ? favHomes.map((item, index) => {
                            let sortedDims = item.plotType.dimensions
                              .slice()
                              .sort(dynamicSort("orderValue"))
                            return (
                              <td key={`fav-floorplan-${item.id}-${index}`}>
                                <div className="item-wrap">
                                  <div className="compare-dims-list">
                                    {sortedDims.map((item) => {
                                      return (
                                        <div
                                          className="compare-dims-item"
                                          key={item.id}
                                        >
                                          <h4 className="room-name">
                                            <strong>{item.name}</strong>
                                          </h4>
                                          <p>{item.imperialDimensions}</p>
                                          <p>{item.metricDimensions}</p>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </td>
                            )
                          })
                        : null}
                    </tr>
                  ) : null}

                  {favHomes.length >= 1 ? (
                    faveHomeBuildingCustomField ? (
                      <tr>
                        <th>Building</th>
                        {favHomes.length >= 1
                          ? favHomes.map((item, index) => {
                              /* Hack to override the building name with custom field */

                              let buildingCustomField =
                                item.customFields.filter((field) => {
                                  if (field.displayName === "Building") {
                                    return field
                                  }
                                })[0]

                              return (
                                <td key={`fav-block-${item.id}-${index}`}>
                                  <div className="item-wrap">
                                    {buildingCustomField
                                      ? buildingCustomField.fieldValue
                                      : null}
                                  </div>
                                </td>
                              )
                            })
                          : null}
                      </tr>
                    ) : null
                  ) : null}

                  {favHomes.length >= 1 ? (
                    faveHomeFloorData ? (
                      <tr>
                        <th>Location</th>
                        {favHomes.length >= 1
                          ? favHomes.map((item, index) => {
                              return (
                                <td key={`fav-block-${item.id}-${index}`}>
                                  <div className="building p-2 pt-3">
                                    <div className="block">
                                      <img
                                        src={item.floorData.imgURL}
                                        alt={item.floorData.floorName}
                                      />
                                      <div
                                        className="block-marker"
                                        style={{
                                          left: `${item.floorData.x * 100}%`,
                                          top: `${item.floorData.y * 100}%`,
                                          backgroundColor: item.floorData.color
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </td>
                              )
                            })
                          : null}
                      </tr>
                    ) : null
                  ) : null}

                  {favHomesCustomFields.length >= 1
                    ? favHomesCustomFields.map((favField, index) => {
                        //Skipping building custom field
                        if (favField.displayName !== "Building")
                          return (
                            <tr key={`fav-field${index}`}>
                              <th>{favField.displayName}</th>
                              {favHomes.length >= 1
                                ? favHomes.map((item, index) => {
                                    let result = null
                                    if (item.customFields) {
                                      result = item.customFields.find((cf) => {
                                        return cf.id === favField.id
                                      })
                                    }

                                    let val = null
                                    if (result) {
                                      if (
                                        result.dataType === 5 &&
                                        result.fieldValue === "true"
                                      ) {
                                        val = "Yes"
                                      } else if (
                                        result.dataType === 5 &&
                                        result.fieldValue === "False"
                                      ) {
                                        val = null
                                      } else {
                                        val = result.fieldValue
                                      }
                                    }

                                    return (
                                      <td
                                        key={`fav-cf-${favField.id}-${index}`}
                                      >
                                        <div className="item-wrap">
                                          {val ? val : "-"}
                                        </div>
                                      </td>
                                    )
                                  })
                                : null}
                            </tr>
                          )
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            {!inMarketingSuite ? (
              <>
                <LoginForm />

                <div className="mt-5">
                  <button
                    onClick={clearFavPlots}
                    className="button-text-link uppercase"
                  >
                    <span className="button-text">Clear All Favourites</span>
                  </button>
                </div>
              </>
            ) : favHomes.length >= 1 ? (
              <FavouritesQRCode />
            ) : null}
          </div>
        </div>
      </div>

      {haveFloorplans && gallerySources.length > 0 ? (
        <FsLightbox
          toggler={lightboxController.toggler}
          sources={gallerySources[lightboxController.index]}
          captions={galleryCaptions[lightboxController.index]}
          key={lightboxController.index}
          openOnMount={lightboxController.openOnMount}
        />
      ) : null}

      <div className="sub-menu centered">
        <div className="container">
          <ButtonGroup aria-label="View" className="view-menu">
            <Button
              active={viewState === "grid" ? true : false}
              variant="secondary"
              onClick={() => setViewState("grid")}
              className="uppercase box-shadow"
            >
              <div className="svg-icon-inline baseline">
                <GridIcon />
              </div>{" "}
              Grid
            </Button>
            <Button
              active={viewState === "compare" ? true : false}
              variant="secondary"
              onClick={() => setViewState("compare")}
              className="uppercase box-shadow"
            >
              <div className="svg-icon-inline baseline">
                <CompareIcon />
              </div>{" "}
              Compare
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </Page>
  )
}

export default Favourite
