import React, { useContext } from "react"
import Page from "../components/Page"
import ZoomContainer from "../components/ZoomContainer"
import StateContext from "../StateContext"
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"
import AreaGuideMenu from "../components/AreaGuideMenu"


function HistoryPage() {
  const appState = useContext(StateContext)

  let historyPageContent = appState.devMenuItems.historyPage
  let zoomImage = null
  if (historyPageContent) {
    zoomImage = historyPageContent.find((obj) => {
      return obj.slug === "zoom-image"
    })
  }

  return (
    <Page title="History" container={false}>
      <BreadcrumbsItem to="/area-guide">Area Guide</BreadcrumbsItem>
      <BreadcrumbsItem to="/area-guide/history">History</BreadcrumbsItem>
      <ZoomContainer zoomImage={zoomImage} />
      <AreaGuideMenu />
    </Page>
  )
}

export default HistoryPage
