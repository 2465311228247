import React from "react"

import { ReactComponent as ZoomInIcon } from "../icons/search-plus-light.svg"
import { ReactComponent as ZoomOutIcon } from "../icons/search-minus-light.svg"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

function ZoomContainer({zoomImage}) {

  const MainWrapper = {
    width: "100%",
    height: "100%"
  }

  return (
    <>
      {zoomImage ? (
        <div className="zoom-container" style={MainWrapper}>
          <TransformWrapper
            initialScale={0.9}
            maxScale={1}
            doubleClick={{
              disabled: true
            }}
            limitToBounds={true}
            zoomAnimation={{ disabled: true }}
            centerOnInit
            wheel={{
              wheelEnabled: false
            }}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <div className="transform-container">
                  <TransformComponent>
                    <img src={zoomImage.url} alt={zoomImage.title} />
                  </TransformComponent>
                </div>
                <div className="tools">
                  <button onClick={zoomOut}>
                    <div className="svg-icon-inline baseline">
                      <ZoomOutIcon />
                    </div>
                  </button>
                  <button onClick={zoomIn}>
                    <div className="svg-icon-inline baseline">
                      <ZoomInIcon />
                    </div>
                  </button>
                </div>
              </React.Fragment>
            )}
          </TransformWrapper>
        </div>
      ) : null}
    </>
  )
}

export default ZoomContainer
