import React, { useState, useContext } from "react"
import PageWrap from "../components/Page"
import { Document, Page, pdfjs } from "react-pdf"
import Loading from "../components/Loading"
import StateContext from "../StateContext"
import { SizeMe } from "react-sizeme"
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"
import { ReactComponent as ArrowLeft } from "../icons/long-arrow-left-light.svg"
import { ReactComponent as ArrowRight } from "../icons/long-arrow-right-light.svg"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function Brochure() {
  const appState = useContext(StateContext)
  let url = appState.devMenuItems.brochure

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  function prev() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  function next() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  return (
    <PageWrap title="Brochure" fluid={false} container={false}>
      <BreadcrumbsItem to="/brochure">Brochure</BreadcrumbsItem>
      {url ? (
        <SizeMe
          monitorHeight={true}
          refreshRate={128}
          refreshMode={"debounce"}
          render={({ size }) => (
            <div className="resize-container">
              <div style={{ maxWidth: size.width, maxHeight: size.height }}>
                <Document
                  file={url}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={Loading}
                  renderMode={"canvas"}
                >
                  <Page
                    pageNumber={pageNumber}
                    height={size.width > size.height ? size.height - 60 : null}
                    width={size.width > size.height ? null : size.width - 50}
                    renderTextLayer={false}
                  />
                </Document>
              </div>
            </div>
          )}
        />
      ) : null}

      <div className="sub-menu">
        <div className="container">
          {numPages > 1 ? (
            <div className="pdf-controls">
              <button
                onClick={prev}
                className="btn btn-secondary"
                disabled={pageNumber === 1 ? true : false}
              >
                <div className="svg-icon-inline baseline">
                  <ArrowLeft />
                </div>
              </button>
              <span className="page-status">
                {pageNumber} of {numPages}
              </span>
              <button
                onClick={next}
                className="btn btn-secondary"
                disabled={pageNumber === numPages ? true : false}
              >
                <div className="svg-icon-inline baseline">
                  <ArrowRight />
                </div>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </PageWrap>
  )
}

export default Brochure
