import React, { useState, useContext, useEffect } from "react"
import { NavLink, useLocation, Link } from "react-router-dom"
import StateContext from "../StateContext"
import { useMediaQuery } from "react-responsive"
import Axios from "axios"

import { Squash as Hamburger } from "hamburger-react"

import BackButton from "./BackButton"

import { ReactComponent as AvailabilityIcon } from "../icons/check-circle-light.svg"
import { ReactComponent as SpecificationIcon } from "../icons/book-open-light.svg"
import { ReactComponent as GalleryIcon } from "../icons/images-light.svg"
import { ReactComponent as LocalAreaIcon } from "../icons/compass-light.svg"
import { ReactComponent as MapIcon } from "../icons/map-marker-check-light.svg"

import { ReactComponent as FavouritesIcon } from "../icons/home-heart-light.svg"
import { ReactComponent as HomesIcon } from "../icons/home-alt-light.svg"
import { ReactComponent as SiteplanIcon } from "../icons/map-marked-light.svg"
import { ReactComponent as VirtualTourIcon } from "../icons/street-view-light.svg"
import { ReactComponent as SitePlan2DIcon } from "../icons/siteplan2d-icon.svg"
import { ReactComponent as DownloadsIcon } from "../icons/download-icon.svg"
import { ReactComponent as BookAppointmentIcon } from "../icons/book-appointment-icon.svg"
import { ReactComponent as BellwayIcon } from "../icons/bellway-b-icon.svg"

import { ReactComponent as FacilitiesIcon } from "../icons/facilities.svg"

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import Crumbs from "./Crumbs"

function Menu() {
  const appState = useContext(StateContext)

  //menu state
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  //check for route change and close menu
  const location = useLocation()
  useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  const handleMediaQueryChange = (matches) => {
    //
  }
  const isDesktopOrLaptop = useMediaQuery(
    { minWidth: 992 },
    undefined,
    handleMediaQueryChange
  )

  //add body class to lock scroll when menu is open
  useEffect(() => {
    if (isMenuOpen === true) {
      disableBodyScroll(document.querySelector(".off-screen-menu-content"))
    } else {
      enableBodyScroll(document.querySelector(".off-screen-menu-content"))
    }
  }, [isMenuOpen])

  let appTitleURL = appState.devMenuItems
    ? appState.devMenuItems.appTitle
    : null
  const [appTitle, setAppTitle] = useState()

  useEffect(() => {
    if (!localStorage.getItem("appTitle") && appTitleURL) {
      const ourRequest = Axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await Axios.get(`${appTitleURL}`, {
            cancelToken: ourRequest.token
          })
          if (response.data) {
            setAppTitle(response.data)
            localStorage.setItem("appTitle", response.data)
          }
        } catch (e) {
          console.log("There was a problem or the request was cancelled.")
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    } else {
      setAppTitle(localStorage.getItem("appTitle"))
    }
  }, [])

  return (
    <>
      <div
        className={
          "off-screen-menu " + (isMenuOpen ? "off-screen-menu--is-visible " : "") +
          (appState.menuPosition === 'left' ? "off-screen-menu--left " : "")
        }
      >
        <div className="off-screen-menu-content">
          <div className="top-menu">
            {appState.devStyle && appState.devStyle.images.tabletLogoUrl ? (
              <p>
                <Link to="/">
                  <img
                    src={appState.devStyle.images.tabletLogoUrl}
                    alt={appState.devName}
                    className="img-fluid development-logo"
                    width="200"
                  />
                </Link>
              </p>
            ) : null}

            <p className="site-description uppercase">{appTitle}</p>
          </div>

          <div className="main-menu">
           

            {/* {appState.devMenuItems && appState.devMenuItems.masterPlan ? (
              <div className="menu-item">
                <NavLink to="/2d-siteplan">
                  <h2>
                    <div className="svg-icon-inline baseline">
                      <SitePlan2DIcon />
                    </div>
                    2D Site Plan
                  </h2>
                </NavLink>
              </div>
            ) : null} */}

            {appState.devMenuItems && (appState.devMenuItems.siteplan || appState.devMenuItems.siteplan2D) ? (
              <div className="menu-item">
                <NavLink to="/siteplan">
                  <h2>
                    <div className="svg-icon-inline baseline">
                      <SiteplanIcon />
                    </div>
                    {appState.devMenuItems.siteplan2D ? 'Site Plan' : '3D Site Plan'}
                  </h2>
                </NavLink>
              </div>
            ) : null}
            {appState.devMenuItems && appState.devMenuItems.siteplan && appState.developmentType === 'Apartments' ? (
              <div className="menu-item">
                <NavLink to="/apartmentFinder">
                  <h2>
                    <div className="svg-icon-inline baseline">
                      <SiteplanIcon />
                    </div>
                    Apartment Finder
                  </h2>
                </NavLink>
              </div>
            ) : null}

            <div className="menu-item">
              <NavLink to="/plots">
                <h2>
                  <div className="svg-icon-inline baseline">
                    <AvailabilityIcon />
                  </div>
                  Availability
                </h2>
              </NavLink>
            </div>

            {appState.developmentType !== 'Apartments' ? 
              <div className="menu-item">
                <NavLink to="/types">
                  <h2>
                    <div className="svg-icon-inline baseline">
                      <HomesIcon />
                    </div>
                    Types
                  </h2>
                </NavLink>
              </div>
            : ''}

            {appState.devMenuItems && appState.devMenuItems.virtualTour ? (
              <div className="menu-item">
                <NavLink to="/exterior-tour">
                  <h2>
                    <div className="svg-icon-inline baseline">
                      <VirtualTourIcon />
                    </div>
                    Exterior Tour
                  </h2>
                </NavLink>
              </div>
            ) : null}

          {appState.devMenuItems && appState.devMenuItems.typeTours ? (
              <div className="menu-item">
                <NavLink to="/interior-tour">
                  <h2>
                    <div className="svg-icon-inline baseline">
                      <VirtualTourIcon />
                    </div>
                    Showhomes
                  </h2>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.facilitiesPage ? (
            <div className="menu-item">
              <NavLink to="/facilities">
                <h2>
                  <div className="svg-icon-inline baseline">
                    <FacilitiesIcon />
                  </div>
                  Facilities
                </h2>
              </NavLink>
            </div>
            ) : null}

            <div className="menu-item">
              <NavLink to="/favourites">
                <h2>
                  <div className="svg-icon-inline baseline">
                    <FavouritesIcon />
                  </div>
                  Favourites
                </h2>
              </NavLink>
            </div>

            {appState.devMenuItems && appState.devMenuItems.brochure ? (
              <div className="menu-item">
                <NavLink to="/brochure">
                  <h2>
                    <div className="svg-icon-inline baseline">
                      <SpecificationIcon />
                    </div>
                    Brochure
                  </h2>
                </NavLink>
              </div>
            ) : null}

            <div className="menu-item">
              <NavLink to="/area-guide">
                <h2>
                  <div className="svg-icon-inline baseline">
                    <LocalAreaIcon />
                  </div>
                   Area Guide
                </h2>
              </NavLink>
            </div>

            <div className="menu-item">
              <NavLink to="/map">
                <h2>
                  <div className="svg-icon-inline baseline">
                    <MapIcon />
                  </div>
                  Map
                </h2>
              </NavLink>
            </div>

            <div className="menu-item">
              <NavLink to="/gallery">
                <h2>
                  <div className="svg-icon-inline baseline">
                    <GalleryIcon />
                  </div>
                  Gallery
                </h2>
              </NavLink>
            </div>

            {appState.devMenuItems && appState.devMenuItems.digitalBrochure ? 
            <div className="menu-item">
              <NavLink to="/digital-brochure">
                <h2>
                  <div className="svg-icon-inline baseline">
                    <SpecificationIcon />
                  </div>
                  Digital Brochure
                </h2>
              </NavLink>
            </div> : null}

            {appState.devMenuItems && appState.devMenuItems.downloads ? (
              <div className="menu-item">
                <NavLink to="/downloads">
                  <h2>
                    <div className="svg-icon-inline baseline">
                      <DownloadsIcon />
                    </div>
                    Downloads
                  </h2>
                </NavLink>
              </div>
            ) : null}

            {appState.devMenuItems && appState.devMenuItems.bookAppointment ? (
              <div className="menu-item">
                <NavLink to="/book-appointment">
                  <h2>
                    <div className="svg-icon-inline baseline">
                      <BookAppointmentIcon />
                    </div>
                    Book Appointment
                  </h2>
                </NavLink>
              </div>
            ) : null}

            <div className="menu-item">
              <NavLink to="/about-bellway">
                <h2>
                  <div className="svg-icon-inline baseline">
                    <BellwayIcon />
                  </div>
                  {appState.brand && appState.brand === "bellway-london" ? "About Bellway London" : "About Bellway"}
                  {/* About Bellway */}
                </h2>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="menu-bar">
        <BackButton />
        <Crumbs devName={appState.devName} />

        <div
          className={
            "menu-toggle " +
            (appState.menuPosition === 'left' ? "menu-toggle--left " : "")
          }
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
          <Hamburger
            rounded
            color="#ffffff"
            size={20}
            toggled={isMenuOpen}
          />
           {isDesktopOrLaptop && 
            <div className="menu-label">Menu</div>
          }
        </div>
      </div>
    </>
  )
}

export default Menu
