import React from "react"
import { Breadcrumbs, BreadcrumbsItem } from "react-breadcrumbs-dynamic"
import { Link } from "react-router-dom"
import { ReactComponent as Arrow } from "../icons/long-arrow-right-light.svg"

const CrumbItem = ({ to, ...props }) => <Link to={to} {...props}></Link>
const CrumbItemLast = ({ to, ...props }) => <b {...props}></b>

function Crumbs(props) {
  const { devName } = props
  return (
    <>
      <div className="breadcrumbs d-none d-md-inline">
        <Breadcrumbs
          separator={
            <span>
              <div className="breadcrumb-separator svg-icon-inline baseline ">
                <Arrow />
              </div>
            </span>
          }
          item={CrumbItem}
          finalItem={CrumbItemLast}
        />
      </div>
    </>
  )
}

export default Crumbs
