import React, { useState, useContext } from "react"
import Page from "../components/Page"
import StateContext from "../StateContext"
import Card from "react-bootstrap/Card"
import "nouislider/distribute/nouislider.css"
import PlotCard from "../components/PlotCard"
import PlotCardList from "../components/PlotCardList"
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import Button from "react-bootstrap/Button"
import { useMediaQuery } from "react-responsive"

//icons

import { ReactComponent as ListIcon } from "../icons/list-icon.svg"
import { ReactComponent as GridIcon } from "../icons/grid-icon.svg"

function AllPlots() {
  //app state
  const appState = useContext(StateContext)

  //view state
  const [viewState, setViewState] = useState("grid")

  const handleMediaQueryChange = (matches) => {
    // switch to the grid view if not desktop
    if (!matches) {
      setViewState("grid")
    }
  }
  const isDesktopOrLaptop = useMediaQuery(
    { minWidth: 992 },
    undefined,
    handleMediaQueryChange
  )

  return (
    <Page title="All Homes" container={true} fluid={false} spaceBelow={true}>
      <BreadcrumbsItem to="/plots">Availability</BreadcrumbsItem>
      <BreadcrumbsItem to="/all-plots">All Homes</BreadcrumbsItem>

      <h2 className="page-title">All Homes</h2>

      {viewState === "grid" ? (
        <div className="plots-grid row" key="plots-grid">
          {appState.plotsData.length >= 1 ? (
            appState.plotsData.map((item) => {
              return (
                <div className="col-md-4 col-lg-3 plot-card item mb-4" key={item.id}>
                  <PlotCard item={item} key={item.id} />
                </div>
              )
            })
          ) : (
            <div className="col-md-12 plot-card item" key={0}>
              <Card className="mb-4">
                <Card.Body>No plots to show.</Card.Body>
              </Card>
            </div>
          )}
        </div>
      ) : (
        <div className="plots-list" key="plots-list">
          <table className="plot-list-table">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Plot</th>
                <th>Floor</th>
                <th>Type</th>
                <th>Bedrooms</th>
                <th>Price</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {appState.plotsData.length >= 1 ? (
                appState.plotsData.map((item) => {
                  return <PlotCardList item={item} key={item.id} />
                })
              ) : (
                <tr className="empty-state-item" key={0}>
                  <td>No plots to show.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      <div className="sub-menu">
        <div className="container">
          {isDesktopOrLaptop ? (
            <ButtonGroup aria-label="View" className="view-menu">
              <Button
                active={viewState === "grid" ? true : false}
                variant="secondary"
                onClick={() => setViewState("grid")}
                className="uppercase box-shadow"
              >
                <div className="svg-icon-inline baseline">
                  <GridIcon />
                </div>{" "}
                Grid
              </Button>
              <Button
                active={viewState === "list" ? true : false}
                variant="secondary"
                onClick={() => setViewState("list")}
                className="uppercase box-shadow"
              >
                <div className="svg-icon-inline baseline">
                  <ListIcon />
                </div>{" "}
                List
              </Button>
            </ButtonGroup>
          ) : null}
        </div>
      </div>
    </Page>
  )
}

export default AllPlots
