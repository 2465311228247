import React, { useContext } from "react";
import Page from "../components/Page";
import StateContext from "../StateContext";

import { ReactComponent as YearsLogo } from "../logos/75-years-logo.svg";
import { ReactComponent as HBFLogo } from "../logos/hbf-5-star-2023.svg";

import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

function Home() {
  const appState = useContext(StateContext);

  let aboutBellwayPage = appState.devMenuItems.aboutBellwayPage;
  let heroImage = "about-bellway-hero.jpg";
  let caption = "Aerial photograph of Lexington Gardens, Nine Elms";
  if (aboutBellwayPage) {
    heroImage = aboutBellwayPage.url;
    caption = aboutBellwayPage.title;
  }

  let brand =
    appState.brand && appState.brand === "bellway" ? "Bellway" : "{brand}";
  switch (appState.brand) {
    case "bellway":
      brand = "Bellway";
      break;
    case "bellway-london":
      brand = "Bellway London";
      break;
    default:
      brand = "{brand}";
  }

  return (
    <Page title="About Bellway" container={false}>
      <div className={appState.brand}>
        <BreadcrumbsItem to="/about-bellway">About {brand}</BreadcrumbsItem>

        <div
          className="about-hero"
          style={{
            backgroundImage: `url("${heroImage}")`,
          }}
        >
          <div className={`hero-content brand-${appState.brand}`}>
            <h1 className="uppercase">
              A REPUTATION BUILT ON SOLID FOUNDATIONS
            </h1>
            <div className="hero-sub">
              <p>
                {brand} has been building exceptional quality new homes
                throughout the UK for over 75 years, creating outstanding
                properties in desirable locations.
              </p>
              <div className="years-logo">
                <YearsLogo />
              </div>
            </div>
          </div>

          <div className="image-caption">{caption}</div>
        </div>

        <div className="about-text container">
          <div className="row">
            <div className="col-lg-6 consumer-code">
              <p>
                {brand} has earned a strong reputation for high standards of
                design, build quality and customer service. From the location of
                the site, to the design of the home, to the materials selected,
                we ensure that our impeccable attention to detail is at the
                forefront of our build process. We create developments which
                foster strong communities and integrate seamlessly with the
                local area. Each year, {brand} commits to supporting education
                initiatives, providing transport and highways improvements,
                healthcare facilities and preserving - as well as creating -
                open spaces for everyone to enjoy.
              </p>

              <p>
                Our high standards are reflected in our dedication to customer
                service and we believe that the process of buying and owning a
                {brand} home is a pleasurable and straightforward one. Having
                the knowledge, support and advice from a committed Bellway
                London team member will ensure your home-buying experience is
                seamless and rewarding, at every step of the way.
              </p>

              <p>
                {brand} abides by The Consumer Code, which is an independent
                industry code developed to make the home buying process fairer
                and more transparent for purchasers.
              </p>
              <img
                className="consumer-code-logo"
                src="consumer-code-for-home-builders.gif"
                alt="Consumer Code for Home Builders"
              ></img>
            </div>
            <div className={`col-lg-6 hbf-section brand-${appState.brand}`}>
              <div className="wrap">
                <HBFLogo />
                <h2 className="uppercase mt-3">
                  AWARDED HIGHEST RATING BY HBF
                </h2>
                <h4>9/10 WOULD RECOMMEND US TO A&nbsp;FRIEND</h4>

                <p>
                  {brand} has been awarded five-star status by the Home Builders
                  Federation, confirming the developer’s place among the
                  country’s elite house-builders. More than nine out of ten
                  customers said they would recommend {brand} to a&nbsp;friend.
                </p>

                <p>
                  The award is the highest accolade a new homes developer can
                  receive. HBF ratings are based on feedback from customers in
                  the weeks after they move into their new home. Only a small
                  handful of housebuilders achieved five-star status
                  this&nbsp;year.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={`about-customer-care brand-${appState.brand}`}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 customer-care-text">
                <h2 className="uppercase">
                  OUR PASSION FOR GREAT CUSTOMER CARE
                </h2>
                <p>
                  <strong>
                    FROM THE FIRST DAY YOU VISIT ONE OF OUR SALES CENTRES TO THE
                    DAY YOU MOVE IN, WE AIM TO PROVIDE A LEVEL OF SERVICE AND
                    AFTER-SALES CARE THAT IS SECOND TO&nbsp;NONE.
                  </strong>
                </p>

                <p>
                  Each home is quality checked by our site managers and sales
                  advisors, after which we invite our customers to a
                  pre-occupation visit. These personalised visits provide a
                  valuable opportunity for homeowners to understand the various
                  running aspects of their new home. On the move-in day our site
                  and sales personnel will be there to ensure that the move-in
                  is achieved as smoothly as&nbsp;possible.
                </p>

                <p>
                  Providing high levels of customer care and building quality
                  homes is our main priority. However, we are aware that errors
                  do sometimes occur and where this happens, it has always been
                  our intention to minimise inconvenience and resolve any
                  outstanding issues at the earliest opportunity. In managing
                  this process we have after sales support that is specifically
                  tasked to respond to all customer enquiries. We have a 24 hour
                  emergency helpline and provide a comprehensive information
                  pack that details the working aspects of a new home. A 10 year
                  NHBC warranty provides further peace of&nbsp;mind.
                </p>

                <p>
                  We are confident that our approach to building and selling new
                  homes coupled with our Customer Care programme will provide
                  you with many years of enjoyment in your new home.
                </p>
              </div>
              <div className="col-lg-6 customer-care-images">
                <img
                  className="customer-service-img"
                  src="customer-service.jpg"
                  alt="Customer Care"
                ></img>

                <div className="awards d-flex flex-row">
                  <div className="award hba">
                    <img
                      src="hba-winner-logo_dark-theme.png"
                      alt="HBA Winner"
                    />
                  </div>
                  <div className="award trustpilot">
                    <img
                      className="mb-1 mt-2"
                      src="trustpilot-logo_dark-theme.png"
                      alt="Trustpilot"
                    />
                    <img
                      src="https://emailsignature.trustpilot.com/signature/en-US/1/58932caa0000ff00059bf27f/stars.png"
                      alt="Trustpilot Stars"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Home;
