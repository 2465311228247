import React, { useEffect, useState, useContext } from "react"
import Page from "../components/Page"
import StateContext from "../StateContext"
import DispatchContext from "../DispatchContext"

import { useParams, Link } from "react-router-dom"
import { useCookies } from "react-cookie"
import Loading from "../components/Loading"

import Carousel from "react-bootstrap/Carousel"
import Table from "react-bootstrap/Table"
import { useHistory } from "react-router-dom"

import {
  dynamicSort,
  stringToSlug,
  priceFormatter
} from "../components/Helpers"

import Badge from "react-bootstrap/Badge"
import PlotDetails from "../components/PlotDetails"
import CustomFields from "../components/CustomFields"

import { ReactComponent as SitePlanIcon } from "../icons/siteplan-icon.svg"
import { ReactComponent as VirtualTourIcon } from "../icons/street-view-light.svg"

import ReactHtmlParser from "react-html-parser"

import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"
import { useLastLocation } from "react-router-last-location"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import Card from "react-bootstrap/Card"
import QRCode from "react-qr-code"

import FavButton from "../components/FavButton"

function SinglePlot(props) {
  const appDispatch = useContext(DispatchContext)
  const appState = useContext(StateContext)
  
const [breadcrumbName, setBreadcrumbName] = useState("test")

  //check if in marketing suite mode
  const [cookies, setCookie, removeCookie] = useCookies(["inMarketingSuite"])
  const [inMarketingSuite, setInMarketingSuite] = useState(
    cookies.inMarketingSuite === "true" ? true : false
  )

  const plotsTitle = 'Availability';
  
  const lastLocation = useLastLocation()
  const history = useHistory()

useEffect(()=>{

if(history.location.state !== undefined){
  setBreadcrumbName(history.location.state.from)
}

},[history.location.state])

  const PlotParentBreadcrumb = () => {
    
    console.log('showSiteplanBlockplanBcrumb ' + appState.showSiteplanBlockplanBcrumb)
        if(history.location.state)
          console.log('plot selection came from ' + history.location.state.from)

          return <BreadcrumbsItem to= {history.location.state.from} >{history.location.state.from}</BreadcrumbsItem>
/*
       if(history.location.state){
         if(history.location.state.from === 'blockplan')  return <BreadcrumbsItem to="/siteplan">Apartment Finder</BreadcrumbsItem> 
        }else{
          if (lastLocation && lastLocation.pathname) {
            if (lastLocation.pathname === "/plots") {
              return <BreadcrumbsItem to="/plots">{plotsTitle}</BreadcrumbsItem>
            } else if (lastLocation.pathname === "/types") {
              return <BreadcrumbsItem to="/types">Types</BreadcrumbsItem>
            } else {
              return <BreadcrumbsItem to="/plots">{plotsTitle}</BreadcrumbsItem>
            }
          } else {
            return <BreadcrumbsItem to="/plots">{plotsTitle}</BreadcrumbsItem>
          }
        }*/
    }

  const currentPlotID = parseInt(useParams().id)

  const [currentPlotData, setCurrentPlotData] = useState()
  const [currentPlotStatus, setCurrentPlotStatus] = useState()
  const [currentPlotView, setCurrentPlotView] = useState()


  useEffect(() => {
    appState.plotsData.filter((el) => {
      if (el.id === currentPlotID) {
        //order images
        let sortedImages = el.plotType.assets.slice().sort(dynamicSort("order"))
        el = { ...el, sortedImages }

        //order dims
        let sortedDims = el.plotType.dimensions
          .slice()
          .sort(dynamicSort("orderValue"))
        el = { ...el, sortedDims }

        setCurrentPlotData(el)

        let status = appState.plotStatuses.filter((status) => {
          if (status.id === el.plotStatusId) {
            return el
          }
          return null
        })[0]

        setCurrentPlotStatus(status)

        //get the current plot view
        let view = null
        if (appState.plotVRs) {
          view = appState.plotVRs.find(
            ({ plot }) => stringToSlug(plot) === `plot-${el.plotNumber}`
          )
        }

        setCurrentPlotView(view)
      }
      return null
    })
  }, [
    appState.plotStatuses,
    appState.plotVRs,
    appState.plotsData,
    currentPlotID
  ])


  //set current light
  useEffect(() => {
    // console.log("run")
    if (currentPlotData) {
      appDispatch({
        type: "setCurrentLight",
        data: { ID: currentPlotData.lightId, status: "on" }
      })
    }
  }, [currentPlotData])

  if (!currentPlotData) {
    return (
      <div className="site-loader">
        <Loading />
      </div>
    )
  }

  return (
    <Page
      title={`Plot ${currentPlotData.plotNumber}`}
      container={true}
      fluid={false}
      spaceBelow={true}
    >
      {/*<PlotParentBreadcrumb />*/}
      <BreadcrumbsItem
        to={`/plots/${currentPlotData.id}`}
      >{`Plot ${currentPlotData.plotNumber}`}</BreadcrumbsItem>

      <div className="row mt-5">
        <div className="col-md-5">
          <Card className="mb-4 plot-card single">
            <Card.Header>
              <div className="card-title">
                <h4 className="plot-name">Plot {currentPlotData.plotNumber}</h4>
                <h5 className="house-name">{currentPlotData.name}</h5>
              </div>
              <FavButton plotID={currentPlotData.id} />{" "}
            </Card.Header>
            <Card.Body>
              <div className="plot-details">
                <PlotDetails
                  item={currentPlotData}
                  status={currentPlotStatus}
                />
                <CustomFields item={currentPlotData} view="icon-list" />
              </div>
            </Card.Body>
            <Card.Footer className="plot-footer">
              {currentPlotStatus.name === "Available" ? (
                <span className="price">
                  {priceFormatter(currentPlotData.price)}
                </span>
              ) : (
                <span>{currentPlotStatus.name}</span>
              )}
            </Card.Footer>
          </Card>

          {currentPlotView ? (
            <div className="plot-menu">
              <div className="item">
                <Link to={`/exterior-tour/${currentPlotView.arg}`}>
                  <div className="svg-icon-inline baseline">
                    <VirtualTourIcon />
                  </div>
                  View on Exterior Tour
                </Link>
              </div>
            </div>
          ) : null}

          <div className="plots-tabs">
            <Tabs defaultActiveKey={currentPlotData.floorData ? 'locator' : 'description'} id="plot-tabs">
            {currentPlotData.floorData ? (
                <Tab eventKey="locator" title="Locator">
                  <div className="building p-2 pt-3">
                    <div className="block">
                      <img
                        src={currentPlotData.floorData.imgURL}
                        alt={currentPlotData.floorData.floorName}
                      />
                      <div
                        className="block-marker"
                        style={{
                          left: `${currentPlotData.floorData.x * 100}%`,
                          top: `${currentPlotData.floorData.y * 100}%`,
                          backgroundColor: currentPlotData.floorData.color
                        }}
                      ></div>
                    </div>
                  </div>
                </Tab>
              ) : null}
              <Tab eventKey="description" title="Description">
                <div className="house-description p-2 pt-3">
                  {ReactHtmlParser(currentPlotData.description)}

                  {inMarketingSuite ?
                    <div className="mt-5">
                      <QRCode value={window.location.href} size={140} fgColor="#676767" />
                    </div>
                  : null}
                </div>
              </Tab>
              <Tab eventKey="profile" title="Dimensions">
                <div className="dims-list">
                  {currentPlotData.sortedDims.map((item) => {
                    return (
                      <div className="dims-item" key={item.id}>
                        <h4 className="room-name">
                          <strong>{item.name}</strong>
                        </h4>
                        <p>{item.imperialDimensions}</p>
                        <p>{item.metricDimensions}</p>
                      </div>
                    )
                  })}
                </div>
                <p className="small ">
                  {currentPlotData.plotType?.disclaimerText}
                </p>
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="col-md-7">
          <Carousel interval={null} controls={false}>
            {currentPlotData.sortedImages.map((img) => {
              return (
                <Carousel.Item key={img.assetId}>
                  <img className="d-block w-100" src={img.fileUrl} alt="" />
                  {/* <Carousel.Caption>
                      <p>{img.name}</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
              )
            })}
          </Carousel>
        </div>
      </div>
    </Page>
  )
}

export default SinglePlot
