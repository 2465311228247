import React, { useContext, useState } from "react"
import { useLocation, useHistory, Switch, Route } from "react-router-dom"
import { useCookies } from "react-cookie"
import { useIdleTimer } from "react-idle-timer"
import DispatchContext from "../DispatchContext"

//Pages
import Home from "../pages/Home"
import Gallery from "../pages/Gallery"
import Types from "../pages/Types"
// import SingleType from "./SingleType"
// import VirtualTour from "./VirtualTour"
import InteriorTour from "../pages/InteriorTour"
import ExteriorTour from "../pages/ExteriorTour"

import Plots from "../pages/Plots"
import AllPlots from "../pages/AllPlots"
import Favourites from "../pages/Favourites"
import SyncFavourites from "../pages/SyncFavourites"
import SinglePlot from "../pages/SinglePlot"
import LocalArea from "../pages/LocalArea"
import Map from "../pages/Map"
import Siteplan from "../pages/Siteplan"
import Blockplan from "../pages/Blockplan"
import ApartmentFinder from "../pages/ApartmentFinder"
import Brochure from "../pages/Brochure"
import SitePlan2D from "../pages/SitePlan2D"
import Downloads from "../pages/Downloads"
import BookAppointment from "../pages/BookAppointment"
import AboutBellway from "../pages/AboutBellway"
import Facilities from "../pages/Facilities"
import HistoryPage from "../pages/HistoryPage"
import Connections from "../pages/Connections"
import MasterplanPage from "../pages/MasterplanPage"
import AppSettings from "../pages/AppSettings"

import NotFound from "../pages/NotFound"
import HybridPlus from "../pages/HybridPlus"

function Routes() {

  // Return to homepage if user is idle for 5mins
  const location = useLocation()
  const history = useHistory()
  const appDispatch = useContext(DispatchContext)
  const [cookies, setCookie, removeCookie] = useCookies(["inMarketingSuite"])
  const [inMarketingSuite, setInMarketingSuite] = useState(
    cookies.inMarketingSuite === "true" ? true : false
  )

  useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: () => {
      if (location.pathname !== "/") {
        if (inMarketingSuite) {
          appDispatch({ type: "clearFavPlots" })
          history.push("/")
        }
      }
    },
    debounce: 250
  })

  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/app-settings/:key">
        <AppSettings />
      </Route>
      <Route path="/gallery">
        <Gallery />
      </Route>
      <Route path="/types" exact>
        <Types />
      </Route>
      {/* <Route path="/types/:id">
        <SingleType />
      </Route> */}
      <Route path="/interior-tour" exact>
        <InteriorTour />
      </Route>
      <Route path="/interior-tour/:id">
        <InteriorTour />
      </Route>
      <Route path="/exterior-tour" exact>
        <ExteriorTour />
      </Route>
      <Route path="/exterior-tour/:id">
        <ExteriorTour />
      </Route>
      <Route path="/area-guide" exact>
        <LocalArea />
      </Route>
      <Route path="/area-guide/local-area">
        <LocalArea />
      </Route>
      <Route path="/area-guide/masterplan">
        <MasterplanPage />
      </Route>
      <Route path="/area-guide/connections">
        <Connections />
      </Route>
      <Route path="/area-guide/history">
        <HistoryPage />
      </Route>

      <Route path="/map">
        <Map />
      </Route>
      <Route path="/plots/" exact>
        <Plots />
      </Route>
      <Route path="/all-plots/" exact>
        <AllPlots />
      </Route>
      <Route path="/plots/:id">
        <SinglePlot />
      </Route>
      <Route path="/siteplan">
        <Siteplan />
      </Route>
      <Route path="/blockplan">
        <Blockplan />
      </Route>
      <Route path="/apartmentFinder">
        <ApartmentFinder />
      </Route>
      <Route path="/favourites/sync">
        <SyncFavourites />
      </Route>
      <Route path="/favourites" exact>
        <Favourites />
      </Route>
      <Route path="/favourites/:view">
        <Favourites />
      </Route>
      <Route path="/facilities">
        <Facilities />
      </Route>
      <Route path="/brochure">
        <Brochure />
      </Route>
      <Route path="/digital-brochure">
        <HybridPlus />
      </Route>
      <Route path="/2d-siteplan">
        <SitePlan2D />
      </Route>
      <Route path="/downloads">
        <Downloads />
      </Route>
      <Route path="/book-appointment">
        <BookAppointment />
      </Route>
      <Route path="/about-bellway">
        <AboutBellway />
      </Route>

      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Routes
