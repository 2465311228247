import React, { useEffect, useState, useContext } from "react"
import { useHistory } from "react-router-dom"

import DispatchContext from "../DispatchContext"

function SyncFavourites() {
  const appDispatch = useContext(DispatchContext)

  const history = useHistory()
  
  //Set favs from URL
  useEffect(() => {
    const search = window.location.search
    if (search) {
      const params = new URLSearchParams(search)
      const favPlots = (params.get('plots')) ? params.get('plots').split(",") : []
      
      if (favPlots) {
        favPlots.forEach((id, i) => {
          appDispatch({
            type: "addFavPlot",
            data: parseInt(id)
          })
        })
      }
    }
    history.push("/favourites")
  }, [])
  
  return (null)
}
  
export default SyncFavourites
  