import React, { useContext } from "react"
import { NavLink, useLocation, Link } from "react-router-dom"
import StateContext from "../StateContext"

import { ReactComponent as MapIcon } from "../icons/map-marker-check-light.svg"
import { ReactComponent as ConnectionsIcon } from "../icons/connections.svg"
import { ReactComponent as HistoryIcon } from "../icons/history.svg"
import { ReactComponent as SiteplanIcon } from "../icons/map-marked-light.svg"

function AreaGuideMenu() {
  const appState = useContext(StateContext)
  const {masterplanPage, connectionsPage, historyPage } = appState.devMenuItems

  return (
    masterplanPage !==null || connectionsPage !== null || historyPage !==null ?
    <div className="area-guide-menu">
      <div className="container">
        <NavLink
          to="/area-guide"
          exact
          className="uppercase box-shadow btn btn-secondary"
        >
          <div className="svg-icon-inline baseline">
            <MapIcon />
          </div>
          Area Guide
        </NavLink>

        {appState.devMenuItems && masterplanPage ? (
          <NavLink
            to="/area-guide/masterplan"
            className="uppercase box-shadow btn btn-secondary"
          >
            <div className="svg-icon-inline baseline">
              <SiteplanIcon />
            </div>
            Masterplan
          </NavLink>
        ) : null}

        {appState.devMenuItems && connectionsPage ? (
          <NavLink
            to="/area-guide/connections"
            className="uppercase box-shadow btn btn-secondary"
          >
            <div className="svg-icon-inline baseline">
              <ConnectionsIcon />
            </div>
            Connections
          </NavLink>
        ) : null}

        {appState.devMenuItems && historyPage ? (
          <NavLink
            to="/area-guide/history"
            className="uppercase box-shadow btn btn-secondary"
          >
            <div className="svg-icon-inline baseline">
              <HistoryIcon />
            </div>
            History
          </NavLink>
        ) : null}
      </div>
    </div>
    : null
  )
}

export default AreaGuideMenu
