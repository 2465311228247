import React from "react"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import MapIcon from "./MapIcon"

function MapMark(props) {

    const { name, color, categoryName } = props;

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip"  {...props}>
        {name}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="top"
            trigger={['click']} 
            overlay={renderTooltip}
            delay={{ show: 250, hide: 400 }}
            rootClose
            rootCloseEvent={'click'}
        >
        <div 
            className="marker" 
            style={{ backgroundColor: color, cursor: 'pointer'}}
        >
            <MapIcon name={categoryName} />
        </div>
        </OverlayTrigger>
    )
}

export default MapMark
