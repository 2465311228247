import React, { useContext, useEffect } from "react"
import StateContext from "../StateContext"
import { SizeMe } from "react-sizeme"
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"
import { NavLink, useLocation, Link } from "react-router-dom"

import { ReactComponent as SiteplanIcon } from "../icons/map-marked-light.svg"

function SitePlan2D() {
  const appState = useContext(StateContext)
  const masterPlan = appState.devMenuItems.masterPlan

  let devName = appState.devName

  useEffect(() => {
    document.title = `2D Site Plan | ${devName}`
    window.scrollTo(0, 0)
  }, [devName])

  return (
    <>
      <BreadcrumbsItem to="/2d-siteplan">2D Site Plan</BreadcrumbsItem>
      <SizeMe
        monitorHeight={true}
        refreshRate={128}
        refreshMode={"debounce"}
        render={({ size }) => (
          <div className="resize-container">
            <img
              className="resize-image"
              style={{ maxWidth: size.width, maxHeight: size.height }}
              src={masterPlan}
              alt=""
            />
          </div>
        )}
      />

      {appState.devMenuItems && appState.devMenuItems.siteplan ? (
        <div className="sub-menu centered">
          <div className="container">
            <Link
              className={`button round uppercase button-secondary box-shadow `}
              to="/siteplan"
            >
              <div className="button-text-link">
                <span className="button-text tour-menu-button">
                  <div
                    className="svg-icon-inline baseline"
                    style={{ pointerEvents: "none" }}
                  >
                    <SiteplanIcon />
                  </div>
                  Siteplan
                </span>
              </div>
            </Link>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default SitePlan2D
