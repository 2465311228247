import React, { useContext } from "react"

import StateContext from "../StateContext"
import QRCode from "react-qr-code"

function FavouritesQRCode() {

  const appState = useContext(StateContext)

  let qrCodeUrl = new URL(window.location.href + '/sync');
  if (appState.favPlots) {
    qrCodeUrl.searchParams.append("plots", appState.favPlots.join(","))
  }

  return (
    <div className="qr-code">
      <h2 className="uppercase">Take your favourites with you</h2>
      <p>Scan the code below to transfer your favourites to your device.</p>
      <div className="mt-5">
        <QRCode value={qrCodeUrl.href} size={140} fgColor="#676767" />
      </div>
    </div>
  )
}

export default FavouritesQRCode
