import React, { useContext, useEffect } from "react";
import Iframe from "../components/Iframe";
import StateContext from "../StateContext";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

function ApartmentFinder() {
  const appState = useContext(StateContext);
  const siteplan = appState.devMenuItems.siteplan;
  let devName = appState.devName;

  useEffect(() => {
    document.title = `Apartment Finder | ${devName}`;
    window.scrollTo(0, 0);
  }, [devName]);

  return (
    <>
      <BreadcrumbsItem to="/apartmentFinder">Apartment Finder</BreadcrumbsItem>
      <Iframe
        src={`${siteplan}?page=blockplan&id=${appState.devID}&key=${appState.key}`}
      ></Iframe>
    </>
  );
}

export default ApartmentFinder;
