import React, { useEffect, useContext, useRef } from "react";
import DispatchContext from "../DispatchContext";
import { useHistory } from "react-router-dom";
import Popover from "./Popover";

function Iframe(props) {
  const { src } = props;
  const iFrameRef = useRef(null);
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const goToRoute = (location) => history.push(location);

  const functionToRun = () => {
    iFrameRef.current.contentWindow.postMessage("hello from parent", "*");
  };

  const handleMessage = (event) => {
    if (
      event.data.source === "virtual-tour" &&
      event.data.payload.event === "route"
    ) {
      goToRoute(event.data.payload.location);
    } else if (
      event.data.source === "virtual-tour" &&
      event.data.payload.event === "popover"
    ) {
      appDispatch({ type: "showPopover", data: event.data.payload.location });
    } else if (
      event.data.source === "virtual-tour" &&
      event.data.payload.event === "navigation"
    ) {
      appDispatch({
        type: "setIframeNavigation",
        data: event.data.payload.value,
      });
    } else if (
      event.data.source === "virtual-tour" &&
      event.data.payload.event === "zoneclicked"
    ) {
      appDispatch({
        type: "setZoneClicked",
        data: event.data.payload.value,
      });
    } else {
      return null;
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      appDispatch({
        type: "setZoneClicked",
        data: "",
      });
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      <iframe
        ref={iFrameRef}
        className="full-iframe"
        src={src}
        width="100%"
        height="100%"
        title="Virtual Tour"
        onLoad={functionToRun}
      ></iframe>
      <Popover />
    </>
  );
}

export default Iframe;
